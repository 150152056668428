import React from 'react';

import { Box } from '@mui/material';
import { Styles } from './Styles';

function APiKeys() {
    return (
        <Box sx={Styles.mainContainer}>
            APiKeys
        </Box>
    )
}

export default APiKeys