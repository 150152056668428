import AxiosAmeyaObj from "../../Configurations/axios-ameya-setup";
import { fetchAccountId, fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails"

class ameyaBackendApi {

    constructor() {
        this.accountId = null;
        this.subscribeId = null;
        this.subscriptionId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
        this.subscribeId = await fetchSubscriberId();
        this.subscriptionId = await fetchSubscriptionId();
    }

    RagFileHandle = async (reqObj) => {
        await this.initialize();
        return AxiosAmeyaObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/event/qms_check_compliance`, reqObj)
    }

    trainQmsModel = async (reqObj) => {
        await this.initialize();
        return AxiosAmeyaObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/event/qms_train_to_pick_facts_from_audit_artifacts`, reqObj)
    }

    qmsUserChatMessage = async (reqObj) => {
        await this.initialize();
        return AxiosAmeyaObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/event/qms_user_chat_message`, reqObj)
    }





}

const PlayGroundApi = new ameyaBackendApi();
export default PlayGroundApi;


