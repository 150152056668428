import React, { useState } from 'react'
import { Box } from '@mui/material';

import { Styles } from './Styles';
import SubSidebar from './SubSidebar/SubSidebar';

import AuditToolPlayground from './AuditTool/AuditToolPlayground';
import AuditToolApi from './AuditTool/AuditToolApi';

import DocSearchPlayground from './DocumentSearch/DocSearchPlayground';
import DocSearchApi from './DocumentSearch/DocSearchApi';


function LaunchHome() {

    const [currentitem, setCurrentItem] = useState('Playground')
    const [currentengine, setCurrentEngine] = useState('')

    const renderDataSource = () => {
        switch (currentitem) {

            case 'Api':
                switch (currentengine) {
                    case 'ai_qms':
                        return <AuditToolApi />;
                    case 'document_search':
                        return <DocSearchApi />;
                }

            case 'Playground':
                switch (currentengine) {
                    case 'ai_qms':
                        return <AuditToolPlayground />;
                    case 'document_search':
                        return <DocSearchPlayground />;
                }
        }
    };

    return (
        <Box sx={Styles.mainContainer}>

            <Box sx={Styles.sidebar}>
                <SubSidebar
                    setCurrentItem={setCurrentItem}
                    setCurrentEngine={setCurrentEngine}
                />
            </Box>

            <main style={Styles.componentContainer}>
                {renderDataSource()}
            </main>

        </Box>
    )
}

export default LaunchHome;