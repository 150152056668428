import React from 'react'
import { Box, LinearProgress } from '@mui/material';
import { mainStyles } from '../styles/styles';

function LoadBar() {
    return (
        <Box sx={mainStyles.loadbarContainer}>
            <LinearProgress sx={mainStyles.loadbar} />
        </Box>
    )
}

export default LoadBar;