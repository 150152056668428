// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datasource-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s ease;
}

.datasource-menu-item .datasource-icon {
  color: #000000;
  margin-right: 8px;
}

.datasource-menu-item .datasource-label {
  color: #000000;
  transition: color 0.3s ease;
}

.datasource-menu-item:hover {
  margin: 10px;
  border-radius: 20px;
  background-color: #DEDEDE;
}

.datasource-menu-item:hover .datasource-icon,
.datasource-menu-item:hover .datasource-label {
  color: #000000;
}

.datasource-menu-item.active {
  margin: 10px;
  border-radius: 20px;
  background-color: #DEDEDE;
}

.datasource-menu-item.active .datasource-icon,
.datasource-menu-item.active .datasource-label {
  color: #000000;
  font-weight: bold;
}

.sidebar_icon svg {
  color: #2a2525;
  transition: color 0.3s ease;
  stroke: #2a2525;
  transition: stroke 0.3s ease;
}

.sidebar_icon:hover svg {
  color: #2a2525;
  stroke: #2a2525;
}

.sidebar_icon.active svg {
  color: #2a2525;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/containers/DataSource/SubSidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,YAAY;EACZ,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;;EAEE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,2BAA2B;EAC3B,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".datasource-menu-item {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 10px;\n  cursor: pointer;\n  margin: 10px;\n  transition: background-color 0.3s ease;\n}\n\n.datasource-menu-item .datasource-icon {\n  color: #000000;\n  margin-right: 8px;\n}\n\n.datasource-menu-item .datasource-label {\n  color: #000000;\n  transition: color 0.3s ease;\n}\n\n.datasource-menu-item:hover {\n  margin: 10px;\n  border-radius: 20px;\n  background-color: #DEDEDE;\n}\n\n.datasource-menu-item:hover .datasource-icon,\n.datasource-menu-item:hover .datasource-label {\n  color: #000000;\n}\n\n.datasource-menu-item.active {\n  margin: 10px;\n  border-radius: 20px;\n  background-color: #DEDEDE;\n}\n\n.datasource-menu-item.active .datasource-icon,\n.datasource-menu-item.active .datasource-label {\n  color: #000000;\n  font-weight: bold;\n}\n\n.sidebar_icon svg {\n  color: #2a2525;\n  transition: color 0.3s ease;\n  stroke: #2a2525;\n  transition: stroke 0.3s ease;\n}\n\n.sidebar_icon:hover svg {\n  color: #2a2525;\n  stroke: #2a2525;\n}\n\n.sidebar_icon.active svg {\n  color: #2a2525;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
