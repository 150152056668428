import React, { useEffect, useState, useRef } from 'react'
import { ReactSVG } from "react-svg";

import {
    Autocomplete, Box, Button, FormControl, TextField, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel,
    SwipeableDrawer, IconButton, InputAdornment, CircularProgress, MenuItem, Select, Pagination,
} from '@mui/material';
import { Styles } from './Styles'
import { fontStyles } from '../../../styles/fontStyles'
import { buttonStyles } from '../../../styles/buttonStyles'
import ProjectSourceFiles from '../../../utils/Data/Sources/ProjectSourceFiles'
import { useDispatch, useSelector } from 'react-redux';
import { setCOAFilesState, setSpecificationFilesState } from "../../../Redux/slice/dataSlice";
import { setSpecificationFilesAdded, setCOAFilesAdded } from "../../../Redux/slice/newDataSlice";
import LoadBar from '../../../utils/LoadBar'
import { searchBarStyles } from '../../../styles/searchBarStyles'
import { tableStyles } from '../../../styles/tableStyles'
import { AdjustRounded, ChatSharp, CheckBoxOutlined, SendOutlined } from '@mui/icons-material'
import { mainStyles } from '../../../styles/styles';

import DocumentsApi from '../../../Api/Services/AmeyaBackendAPI/DocumentsApi'
import { tostAlert } from '../../../utils/AlertToast'
import { fetchSubscriberId, fetchSubscriptionId } from '../../../utils/GetAccountDetails'
import JsonSourceFiles from '../../../utils/Data/Sources/JsonSourceFiles'
import PdfSourceFiles from '../../../utils/Data/Sources/PdfSourceFiles'
import PlayGroundApi from '../../../Api/Services/AmeyaBackendAPI/PlayGrpondApi'
import ChatHandler from '../../../components/ChatSideDrawer/ChatHandler';
import { IconSvg } from '../../../utils/globalIcons';
import AmeyaQmsChatApi from '../../../Api/Services/AmeyaBackendAPI/QmsChatApi';

function AuditToolPlayground() {

    const dispatch = useDispatch()

    const [coafiles, setCOAFiles] = useState([])
    const [specificationfiles, setSpecificationFiles] = useState([])
    const [loading, setLoading] = useState(false)

    // Redux States
    const coa_files_data = useSelector(state => state.all_data.coafiles)
    const specification_files_data = useSelector(state => state.all_data.specificationfiles)
    const coa_files_added = useSelector(state => state.data_added.coa_files_added)
    const specification_files_added = useSelector(state => state.data_added.specification_files_added)

    const [selectedpdffile, setSelectedPdfFile] = useState(null)
    const [selectedjsonfile, setSelectedJsonFile] = useState(null)
    const documentprocessdetails = {
        test_id: null,
        total_pass: null,
        total_tests: null,
        test_result: [],
        test_status: 'loading'
    }
    const [documentprocessdata, setDocumentProcessData] = useState(documentprocessdetails)
    const [processDatarowsPerPage, setProcessDataRowsPerPage] = useState('5');
    const [processDataPage, setProcessDataPage] = useState(1);
    const [totalProcessDataPages, setTotalProcessDataPages] = useState(1);

    // QMS Model Chat Handle
    const chatWindowRef = useRef(null);
    const [chatdrawer, setChatDrawer] = useState({ state: false })
    const [qmsChatData, setQmsChatData] = useState([]);
    const qmsChatDetails = { chat: '', feed_back: false, feedback_type: '', disable_chat: false }
    const [newQmsChat, setQmsChat] = useState(qmsChatDetails);
    const [qmsSystemResponse, setQmsSystemResponse] = useState({
        sessionId: null,
        block_user_text_input: false,
        next_user_action_type: 'text or feedback_thumps_up or feedback_options, or restart',
        feedback_options: []
    })

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [qmsChatData]);

    useEffect(() => {
        getsourceFilesDetails()
    }, [coa_files_added, specification_files_added,])

    const getCoaSourceFiles = async () => {
        if (coa_files_data?.length > 0 && coa_files_added === false) {
            return coa_files_data
        } else {
            const response = await PdfSourceFiles();
            dispatch(setCOAFilesState(response))
            dispatch(setCOAFilesAdded(false))
            return response
        }
    }

    const getSpecificationSourceFiles = async () => {
        if (specification_files_data?.length > 0 && specification_files_added === false) {
            return specification_files_data
        } else {
            const response = await JsonSourceFiles();
            dispatch(setSpecificationFilesState(response))
            dispatch(setSpecificationFilesAdded(false))
            return response
        }
    }

    const getsourceFilesDetails = async () => {
        setLoading(true)
        try {
            const [coaResponse, jsonResponse] = await Promise.all([
                getCoaSourceFiles(),
                getSpecificationSourceFiles()
            ])

            if (coaResponse && coaResponse?.length > 0) {
                setCOAFiles(coaResponse)
            }

            if (jsonResponse && jsonResponse?.length > 0) {
                setSpecificationFiles(jsonResponse)
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const toggleDrawer = (open) => (event) => {
        // if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //     return;
        // }
        setChatDrawer({ ...chatdrawer, state: open });
    };

    const handlePdfFileSelection = async (event, item) => {
        event.preventDefault()
        setSelectedPdfFile(item)
    }

    const handleJsonFileSelection = async (event, item) => {
        event.preventDefault()
        setSelectedJsonFile(item)
    }

    //Handle Document APi
    const handleDocuments = async () => {
        setLoading(true)
        try {
            if (selectedpdffile && selectedjsonfile) {

                const reqObj = {
                    "json_file_name": selectedjsonfile?.file_name,
                    "pdf_file_name": selectedpdffile?.file_name,
                }
                const response = await PlayGroundApi.RagFileHandle(JSON.stringify(reqObj))
                if (response.status === 200) {
                    const reposne_data = response?.data?.results ?? []
                    setDocumentProcessData({
                        ...documentprocessdata,
                        test_id: response?.data?.test_id,
                        test_result: reposne_data?.output?.length > 0 ? reposne_data?.output : [],
                        total_pass: reposne_data?.total_pass,
                        total_tests: reposne_data?.total_tests,
                        test_status: reposne_data?.status_code === 200 || 206 ? 'success' : 'failed'
                    })
                }
                return
            } else {
                tostAlert('File Not Selected or Invalid File', 'custom_warning')
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setTotalProcessDataPages(Math.ceil(documentprocessdata?.test_result?.length / parseInt(processDatarowsPerPage)));
    }, [documentprocessdata?.test_result, processDatarowsPerPage]);

    let PaginatedProcessData = [];
    const handleProcessDataPage = (event, newPage) => { setProcessDataPage(newPage) }

    const handleProcessDataRowsPerPageChange = event => {
        setProcessDataRowsPerPage(event.target.value);
        const newRowsPerPage = event.target.value;
        const newTotalPages = Math.ceil(documentprocessdata?.test_result?.length / parseInt(newRowsPerPage));
        setProcessDataRowsPerPage(newRowsPerPage);
        setProcessDataPage(prevPage => (prevPage <= newTotalPages ? prevPage : newTotalPages));
        setTotalProcessDataPages(newTotalPages);
    };
    if (documentprocessdata?.test_result?.length > 0) {
        const startIndex = (processDataPage - 1) * parseInt(processDatarowsPerPage);
        const endIndex = Math.min(startIndex + parseInt(processDatarowsPerPage), documentprocessdata?.test_result?.length);
        PaginatedProcessData = documentprocessdata?.test_result?.slice(startIndex, endIndex);
    }

    // Handle Train The Data
    const handleTrainQmsModel = async (testId) => {
        setQmsChatData([]);
        setLoading(true)
        try {
            const response = await AmeyaQmsChatApi.trainQmsModel(testId)
            if (response.status === 200) {
                const responseData = response?.data
                const initiate_chat = responseData?.initate_chat
                if (initiate_chat) {
                    setQmsChatData((prevChat) => [...prevChat, { chat: responseData?.system_message, sender: 'system' }]);
                    setChatDrawer({ ...chatdrawer, state: true })
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleQmsChat = async () => {
        try {
            if (newQmsChat.chat.trim() !== '') {

                const userMessage = { chat: newQmsChat.chat, sender: 'user' };
                setQmsChat({ ...newQmsChat, disable_chat: true })
                setQmsChatData([...qmsChatData, userMessage]);

                if (!newQmsChat.feed_back) {
                    const reqObj = {
                        "test_id": documentprocessdata.test_id,
                        "user_message": newQmsChat.chat,
                        "user_action_type": 'text',
                        "session_id": qmsSystemResponse.sessionId,
                        "end_chat_session": false
                    }
                    const response = await AmeyaQmsChatApi.qmsUserChatMessage(reqObj);
                    if (response.status === 200) {
                        setQmsChat(qmsChatDetails);
                        const responseData = response?.data
                        const machineResponse = responseData?.system_message
                        const sessionId = responseData?.session_id
                        const blockUserTextInput = responseData?.block_user_text_input
                        const feedBackOptions = responseData?.feedback_options
                        const nextUserActionType = responseData?.next_user_action_type

                        setQmsChatData((prevMessages) => [
                            ...prevMessages,
                            { chat: machineResponse, sender: 'system' },
                        ]);

                        setQmsSystemResponse({
                            ...qmsSystemResponse,
                            sessionId: sessionId,
                            block_user_text_input: blockUserTextInput,
                            feedback_options: feedBackOptions,
                            next_user_action_type: nextUserActionType
                        })
                    }
                } else {
                    setChatDrawer({ ...chatdrawer, state: false })
                    const reqObj = {
                        "test_id": documentprocessdata.test_id,
                        "user_message": true,
                        "user_action_type": 'feedback',
                        "session_id": qmsSystemResponse.sessionId,
                        "end_chat_session": newQmsChat.feedback_type === "feedback_thumps_up" ? true : false
                    }
                    await AmeyaQmsChatApi.qmsUserChatMessage(reqObj);
                    setQmsChatData([])
                    setQmsChat(qmsChatDetails);
                }
            }
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <Box sx={Styles.mainContainer}>
            {loading && (<LoadBar />)}


            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography sx={fontStyles.largeText}>Playground</Typography>

                <Button sx={{ ...buttonStyles.primaryBtn, width: '100px', height: '35px' }}
                    onClick={() => handleDocuments()}
                >
                    {
                        documentprocessdata?.test_status === "success" ?
                            (<Typography style={fontStyles.btnText}>Resubmit</Typography>)
                            :
                            (<Typography style={fontStyles.btnText}>SUBMIT</Typography>)
                    }
                </Button>
            </Box>

            <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                Test AI QMS Agent
            </Typography>

            <Box marginTop={'20px'} display={'flex'} alignItems={'center'}>

                <FormControl>
                    <Typography sx={{ ...fontStyles.smallText, marginBottom: '6px' }}>Select a document</Typography>
                    <Autocomplete
                        sx={{ ...searchBarStyles.searchBar, width: '300px' }}
                        options={coafiles}
                        getOptionLabel={(option) => option?.file_name}
                        id="clear-on-escape"
                        clearOnEscape
                        value={selectedpdffile}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Document" />
                        )}
                        onChange={handlePdfFileSelection}
                    />
                </FormControl>

                <FormControl sx={{ marginLeft: '10px' }}>
                    <Typography sx={{ ...fontStyles.smallText, marginBottom: '6px' }}>Select a specification</Typography>
                    <Autocomplete
                        sx={{ ...searchBarStyles.searchBar, width: '300px' }}
                        options={specificationfiles}
                        getOptionLabel={(option) => option?.file_name}
                        id="clear-on-escape"
                        clearOnEscape
                        value={selectedjsonfile}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Specification" />
                        )}
                        onChange={handleJsonFileSelection}
                    />
                </FormControl>

            </Box>

            {documentprocessdata?.test_status === "success" &&
                <Box marginTop={'30px'} paddingBottom={'20px'}>

                    <Typography sx={fontStyles.mediumText}>
                        Results
                    </Typography>

                    <Box marginTop={'20px'} paddingBottom={'20px'}>
                        <Paper>
                            < TableContainer component={Paper} sx={tableStyles.tcontainer}>
                                <Table
                                    stickyHeader sx={{ height: documentprocessdata?.test_result?.length <= 0 ? '400px' : `${documentprocessdata?.test_result?.length}px` }}
                                >
                                    <TableHead sx={tableStyles.thead}>
                                        <TableRow>
                                            <TableCell sx={{ ...tableStyles.thcell, width: '30%' }}>
                                                <TableSortLabel sx={tableStyles.sortlabel}
                                                // active={productionfilesortKey === 'payload.productionfile_name.en'}
                                                // direction={productionfilesortKey === 'payload.productionfile_name.en' ? productionfilesortDirection : 'asc'}
                                                // onClick={() => handleProductionFileSort('payload.productionfile_name.en')}
                                                >
                                                    <Typography sx={tableStyles.theadText}>Specification</Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ ...tableStyles.thcell, width: '30%' }}>
                                                <TableSortLabel sx={tableStyles.sortlabel}
                                                // active={productionfilesortKey === 'payload.created_on'}
                                                // direction={productionfilesortKey === 'payload.created_on ' ? productionfilesortDirection : 'asc'}
                                                // onClick={() => handleProductionFileSort('payload.created_on')}
                                                >
                                                    <Typography sx={tableStyles.theadText}>Report Value</Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ ...tableStyles.thcell, width: '40%' }}>
                                                <TableSortLabel sx={tableStyles.sortlabel}
                                                // active={productionfilesortKey === 'payload.created_on'}
                                                // direction={productionfilesortKey === 'payload.created_on ' ? productionfilesortDirection : 'asc'}
                                                // onClick={() => handleProductionFileSort('payload.created_on')}
                                                >
                                                    <Typography sx={tableStyles.theadText}>Result</Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        {documentprocessdata?.test_result?.length === 0 && loading ?
                                            (<TableRow sx={tableStyles.emptytr}>
                                                <TableCell colSpan={7} sx={tableStyles.emptytdcell}>
                                                    <CircularProgress />
                                                </TableCell>
                                            </TableRow>)
                                            :
                                            (documentprocessdata?.test_result?.length > 0 && PaginatedProcessData?.map((item, index) => (
                                                < TableRow key={index} sx={tableStyles.trow}>
                                                    <TableCell style={tableStyles.tdcell}>{item?.test_name}</TableCell>
                                                    <TableCell style={tableStyles.tdcell}>
                                                        {item?.status === "pass" ?
                                                            <Box>{item?.result_value}&nbsp;{item?.uom}</Box>
                                                            : <Typography sx={{ ...fontStyles.smallText, color: "#C30E2E", fontWeight: 'bold' }}>UNKNOWN</Typography>}
                                                    </TableCell>
                                                    <TableCell style={tableStyles.tdcell}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
                                                            {item?.status === "pass" ?
                                                                (<Box marginRight={'30px'}>
                                                                    <ReactSVG src={IconSvg.resultCorrectIcon} />
                                                                </Box>) :
                                                                (<Box marginRight={'30px'} display={'flex'} alignItems={'center'}>
                                                                    <ReactSVG src={IconSvg.resultErrorIcon} />
                                                                    <Button
                                                                        sx={{ ...buttonStyles.tertiaryBtn, width: '80px', marginLeft: '10px' }}
                                                                        onClick={() => handleTrainQmsModel(documentprocessdata.test_id)}
                                                                    >
                                                                        <Typography style={{ ...fontStyles.btnSmallText, fontWeight: 'bold' }}>Train</Typography>
                                                                    </Button>
                                                                </Box>)
                                                            }
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )))
                                        }
                                        {!loading && documentprocessdata?.test_result?.length === 0 && (
                                            <TableRow sx={tableStyles.emptytr}>
                                                <TableCell colSpan={8} sx={tableStyles.emptytdcell}>
                                                    No records to display
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>

                                </Table>
                            </TableContainer >

                            {documentprocessdata?.test_result?.length > 0 &&
                                (<Box display={'flex'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Box marginLeft={'20px'}>
                                            <Select
                                                value={processDatarowsPerPage}
                                                onChange={handleProcessDataRowsPerPageChange}
                                                sx={{ width: '110px', height: '35px' }}
                                            >
                                                <MenuItem value={'5'}>5 rows</MenuItem>
                                                <MenuItem value={'10'}>10 rows</MenuItem>
                                                <MenuItem value={'15'}>15 rows</MenuItem>
                                                <MenuItem value={'20'}>20 rows</MenuItem>
                                            </Select>
                                        </Box>
                                    </Box>
                                    <Pagination
                                        count={totalProcessDataPages}
                                        page={processDataPage}
                                        onChange={handleProcessDataPage}
                                        color="standard"
                                    />
                                </Box>)
                            }

                        </Paper>
                    </Box>
                </Box>
            }

            {documentprocessdata?.test_status === "failed" &&
                <Box sx={Styles.unProcessDocumentContainer}>
                    <Box padding={'20px'}>
                        <Box display={'flex'} justifyContent={'center'}>
                            <Box marginRight={'10px'}>
                                <AdjustRounded color='error' />
                            </Box>
                            <Typography sx={{ ...fontStyles.smallText, color: 'red' }}>
                                COUD NOT PROCESS DOCUMENT
                            </Typography>
                        </Box>
                        <Box marginTop={'5px'} display={'flex'} justifyContent={'center'}>
                            <Button
                                sx={{ ...buttonStyles.tertiaryBtn, width: '80px' }}
                                onClick={toggleDrawer(true)}
                            >
                                <Typography style={fontStyles.btnSmallText}>TRAIN</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            }

            <ChatHandler
                chatdrawer={chatdrawer}
                toggleDrawer={toggleDrawer}
                chatWindowRef={chatWindowRef}
                qmsChatData={qmsChatData}
                setQmsChat={setQmsChat}
                newQmsChat={newQmsChat}
                handleQmsChat={handleQmsChat}
                qmsSystemResponse={qmsSystemResponse}
            />

        </Box >
    )
}

export default AuditToolPlayground;