import { jwtDecode } from "jwt-decode";

export const fetchAccountId = async () => {
    try {
        return process.env.REACT_APP_APPFLYTE_ACCOUNT_ID
        // const dpod_token = JSON.parse(localStorage.getItem('dpod-token'));
        // if (dpod_token) {
        //     const decoded_dpod_token = jwtDecode(dpod_token);
        //     return decoded_dpod_token?.root_account_id ? decoded_dpod_token?.root_account_id : null
        // } else {
        //     console.warn('Account ID not found.');
        //     return null;
        // }
    } catch (error) {
        console.warn(error)
        return null
    }
};

export const fetchSubscriberId = async () => {
    try {
        return process.env.REACT_APP_APPFLYTE_SUBSCRIBER_ID
        // const dpod_token = JSON.parse(localStorage.getItem('dpod-token'));
        // if (dpod_token) {
        //     const decoded_dpod_token = jwtDecode(dpod_token);
        //     return decoded_dpod_token?.subscriber_id ? decoded_dpod_token?.subscriber_id : null
        // } else {
        //     console.warn('subscriber_id ID not found.');
        //     return null;
        // }
    } catch (error) {
        console.warn(error)
        return null
    }
};

export const fetchSubscriptionId = async () => {
    try {
        return process.env.REACT_APP_APPFLYTE_SUBSCRIPTION_ID
        // const dpod_token = JSON.parse(localStorage.getItem('dpod-token'));
        // if (dpod_token) {
        //     const decoded_dpod_token = jwtDecode(dpod_token);
        //     return decoded_dpod_token?.subscription_id ? decoded_dpod_token?.subscription_id : null
        // } else {
        //     console.warn('subscription_id not found.');
        //     return null;
        // }
    } catch (error) {
        console.warn(error)
        return null
    }
};

export const fetchUserId = async () => {
    try {
        // return "a9352cc9-59b5-4abc-8e04-f96c258d1b01"
        // return "f7b911f7-e8b9-4dc6-a460-513c3740e60f"
        const dpod_token = JSON.parse(localStorage.getItem('dpod-token'));
        if (dpod_token) {
            const decoded_dpod_token = jwtDecode(dpod_token);
            return decoded_dpod_token?.sub ? decoded_dpod_token?.sub : null
        } else {
            console.warn('user_Id not found.');
            return null;
        }
    } catch (error) {
        console.warn(error)
        return null
    }
};

export const fetchTenentId = async () => {
    try {
        return "f7b911f7-e8b9-4dc6-a460-513c3740e60f"
        // const dpod_token = JSON.parse(localStorage.getItem('dpod-token'));
        // if (dpod_token) {
        //     const decoded_dpod_token = jwtDecode(dpod_token);
        //     return decoded_dpod_token?.sub ? decoded_dpod_token?.sub : null
        // } else {
        //     console.warn('user_Id not found.');
        //     return null;
        // }
    } catch (error) {
        console.warn(error)
        return null
    }
};


export const getUserInitials = () => {
    try {
        const name = JSON.parse(localStorage.getItem('UserName'));
        if (!name) return "";
        const words = name.trim().split(" ");
        if (words.length === 1) {
            return words[0].charAt(0).toUpperCase();
        }
        return (words[0].charAt(0) + words[words.length - 1].charAt(0)).toUpperCase();
    } catch (error) {
        console.log(error)
    }
};


export const getUserName = () => {
    try {
        const name = JSON.parse(localStorage.getItem('UserName'));
        if (!name) return "";
        return name
    } catch (error) {
        console.log(error)
    }
};
