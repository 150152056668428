import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Styles } from './Styles';
import { Box, Button, Card, CardContent, Grid2 as Grid, IconButton, Typography } from '@mui/material';
import { buttonStyles } from '../../styles/buttonStyles';
import { fontStyles } from '../../styles/fontStyles';
import { mainStyles } from '../../styles/styles';
import { MoreVert } from '@mui/icons-material';

import { setCurrentSpace } from "../../Redux/slice/currentSpaceSlice";
import { setSpacesState } from "../../Redux/slice/dataSlice";

import LoadBar from '../../utils/LoadBar';
import getSpaceData from '../../utils/Data/SpaceData';
import { setWorkspaceAddedAdded } from '../../Redux/slice/newDataSlice';

function Spaces() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const all_spaces = useSelector(state => state.all_data.spaces)
    const workspace_added = useSelector(state => state.data_added.workspace_added)

    const [spaces, setSpaces] = useState([])
    const [spaceLoading, setSpaceLoading] = useState(false);

    useEffect(() => {
        getSpaceDetails()
    }, [])

    const getSpaceDetails = async () => {
        setSpaceLoading(true)
        try {
            if (all_spaces?.length > 0 && workspace_added === false) {
                setSpaces(all_spaces)
                return
            }
            const response = await getSpaceData()
            dispatch(setSpacesState(response))
            dispatch(setWorkspaceAddedAdded(false))
            setSpaces(response)
        } catch (error) {
            console.error(error);
        } finally {
            setSpaceLoading(false)
        }
    }

    const handleSpaceSelection = async (item) => {
        dispatch(setCurrentSpace(item))
        navigate(`/spaces/${item}/projects`)
    }

    const handleSpaceCreation = async () => {
        navigate(`/spaces/add`)
    }

    return (
        <div style={Styles.mainContainer}>
            {spaceLoading && <LoadBar />}
            <Box padding={'20px'}>

                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography sx={fontStyles.primaryText}>All Spaces</Typography>
                    <Button
                        sx={{ ...buttonStyles.primaryBtn, width: '110px' }}
                        onClick={() => handleSpaceCreation()}
                    >
                        <Typography sx={fontStyles.btnText}>New Space</Typography>
                    </Button>
                </Box>

                <Box marginTop={'50px'}>
                    <Grid container spacing={6}>
                        {spaces && spaces.length > 0 ? spaces?.map((space, index) => (
                            <Grid size={{ xs: 6, md: 3 }} key={index} >
                                <Card sx={Styles.card}>
                                    <CardContent sx={{ padding: '0' }} onClick={() => handleSpaceSelection(space?.payload?.workspace_name?.en)}>
                                        <Box sx={Styles.cardHeader}>
                                            <Typography sx={fontStyles.mediumText}>
                                                {space?.payload?.workspace_name?.en}
                                            </Typography>
                                            <IconButton>
                                                <MoreVert />
                                            </IconButton>
                                        </Box>
                                        <Box sx={Styles.cardBody}>
                                            <Typography sx={fontStyles.smallText}>
                                                {space?.payload?.workspace_description?.en}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )) : (spaces.length === 0 && !spaceLoading) && (
                            <Box sx={mainStyles.noRecord}>
                                <Typography>No Records To Display</Typography>
                            </Box>
                        )}
                    </Grid>
                </Box>



            </Box>

        </div>
    )
}

export default Spaces;