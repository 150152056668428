import React, { useState } from 'react'
import { Box } from '@mui/material';

import DataSourceDatabse from './Database/DataSourceDatabse';
import DataSourceLinks from './Links/DataSourceLinks';
import DataSourceSightMaps from './SightMap/DataSourceSightMaps';
import { Styles } from './Styles';
import SubSidebar from './SubSidebar/SubSidebar';

// Document Handling
import DocSearchFiles from './DocumentSearch/DocSearchFiles';
import AuditToolFiles from './AuditTool/AuditToolFiles/AuditToolFiles';

function DataSourceHome() {

    const [currentsource, setCurrentSource] = useState('Files')
    const [currentengine, setCurrentEngine] = useState('')

    const renderDataSource = () => {
        switch (currentsource) {
            case 'Files':
                switch (currentengine) {
                    case 'document_search':
                        return <DocSearchFiles />;
                    case 'ai_qms':
                        return <AuditToolFiles />;
                }
            // case 'Database':
            //     return <DataSourceDatabse />;
            case 'Links':
                return <DataSourceLinks />;
            case 'Sitemap':
                return <DataSourceSightMaps />;
        }
    };


    return (
        <Box sx={Styles.mainContainer}>

            <Box sx={Styles.sidebar}>
                <SubSidebar
                    setCurrentSource={setCurrentSource}
                    setCurrentEngine={setCurrentEngine}
                />
            </Box>

            <main style={Styles.componentContainer}>
                {renderDataSource()}
            </main>

        </Box>
    )
}

export default DataSourceHome;