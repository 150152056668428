import axios from "axios";

const AxiosAmeyaObj = axios.create(
    {
        baseURL: process.env.REACT_APP_QMS_AUDIT_TOOL_URL,
        responseType: "json"
    }
)

const requestHandler = async (request) => {
    const JwtToken = JSON.parse(localStorage.getItem("dpod-token"))
    // const JwtToken = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb290X2FjY291bnRfaWQiOiI0ZDFlNGJkNy0wMjIxLTQzNGEtOTI2Ny01MzNkMDZkYmE4NDEiLCJyb290X3VzZXJfaWQiOiI4MmU3NWUyMC0xOTNmLTQyYmUtOTczMC0wZGMxZjM5M2I2YmIiLCJyb290X3VzZXJuYW1lIjoibmlhbC5qYWhzaXJlQGRvYWRzLm9yZyIsInBhc3N3b3JkIjoicGJrZGYyOnNoYTI1NjoxNTAwMDAkb094NUViM24kZjljMWI2YjdmMmMxNzNiNTYyNGM3ODM0ODFjNTM3OWE2NzY0OGMwNDM5MTZlOTJmZDhhYmE5MTY4MWVjYmFmMCIsInN1YnNjcmliZXJfaWQiOiJiZjE1ZmZkNS00NDQ0LTRkNzgtOWRhNC1jMGQyOGVmMTE1N2YiLCJzdWJzY3JpcHRpb25faWQiOiI0OTA2NjE5NS03Y2IxLTQwZjMtOTVjMS1kZjA1ZWExMDBlZTIiLCJzdWJzY3JpcHRpb25fc3RhcnRfZGF0ZSI6IjIwMjQtMDktMTkiLCJzdWJzY3JpcHRpb25fZW5kX2RhdGUiOiIyMDI0LTEyLTE4IiwiYXBwX3N1YnNjcmliZWQiOlsiYXBwZmx5dGUtZnJvbnRlbmQiLCJhcHBmbHl0ZS1iYWNrZW5kIiwiSW1waWxvcyIsIklTU0FDIl0sImRhdGFiYXNlIjoiY29zbW9zIiwiaGFzaF9zZWNyZXQiOiJhNGQwNTA5NGFkZWM4YmE5MGNmYjk0OGQxZmZjNmNmM2ZiOWJjNTYzOGIyZDQ4YzM1MjRiYmZkMjViMTE4Mzk4IiwiZHluYW1vIjpudWxsLCJjb3Ntb3MiOnsiY29udGFpbmVyX25hbWUiOiJhcHBseXRlLXRhYmxlLTQ5MDY2MTk1LTdjYjEtNDBmMy05NWMxLWRmMDVlYTEwMGVlMiIsImRhdGFiYXNlX25hbWUiOiJhcHBmbHl0ZS1kYi00OTA2NjE5NS03Y2IxLTQwZjMtOTVjMS1kZjA1ZWExMDBlZTIiLCJhenVyZV9hY2Nlc3Nfa2V5X2lkIjoiZHBvZHRlc3Rjb2xsZWN0aW9uczUifSwiZXhwIjoxNzM0NDgwMDAwfQ.KyJjVpFaNUNDkpthpwHx_rZ7nJ7zfxsiYBVp0OGvttQQeBhaRVX78SLnLjwCpd69-B-0Mlx5W5ohyvBfgVN42VTtIQ85Qqak4gr1qGzoUgjT3wCTWUxpQ69zDy6qy7rS4tTBPu5OY3qbD4Cd5cACSSMsueTBMGwIzuQRaB3HHDTH-_vixhxFQfSZGfKVYE5rQgf3WDq-xOfgHaAB2LfiPAP3oXoGP2taFhcehUm8L252aGH0yyUg-mNNVxKYcgKPHwEm_qG9zqKArFKy8UdgaKV_CMW9Q7wgI_DoyILIkaBYzdjjxERRhL9z4IzMALC5KM5xfPp9KuEx-fQIiqi3tA"
    request.headers['Authorization'] = `Bearer ${JwtToken}`;
    request.headers['Content-Type'] = 'application/json';
    request.headers['Accept'] = 'application/json';
    return request
}

AxiosAmeyaObj.interceptors.request.use(
    request => requestHandler(request)
)

export default AxiosAmeyaObj;

