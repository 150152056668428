import React, { useEffect, useState } from 'react';


import { Typography } from '@mui/material';
import { Menu, Apps, Home, Folder, Settings, Launch, Workspaces, DescriptionOutlined, LanguageOutlined, NetworkCellOutlined, DataObjectOutlined } from '@mui/icons-material';
import { Styles } from "./Styles"

import './Sidebar.css';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconSvg } from '../../../utils/globalIcons';
import { ReactSVG } from "react-svg";


function SubSidebar({ setCurrentSource, setCurrentEngine }) {

  const location = useLocation();
  const currentPath = location.pathname;
  const current_space = useSelector(state => state.current_space)
  const current_project = useSelector(state => state.current_project)
  const selected_project = useSelector(state => state.selected_data.selected_project)

  const [selectedMenuItem, setSelectedMenuItem] = useState(0);

  useEffect(() => {
    const selected_engine_name = selected_project?.project_engine_config?.engine_name
    setCurrentEngine(selected_engine_name)
  }, [selected_project])


  const menuItems = [
    { icon: IconSvg.filesIcon, label: 'Files', engine_name: "", icon_type: "svg" },
    { icon: <LanguageOutlined />, label: 'Links', engine_name: "", icon_type: "icon" },
    { icon: <NetworkCellOutlined />, label: 'Sightmap', engine_name: "", icon_type: "icon" },
    { icon: <DataObjectOutlined />, label: 'Database', engine_name: "", icon_type: "icon" },
  ];

  const filteredMenuItems = () => {
    let items = []
    if (current_space && current_project && selected_project) {
      const selected_engine_name = selected_project?.project_engine_config?.engine_name
      const selected_sidebar_items = selected_project?.project_engine_config?.engine_config?.sidebar_items ?? {}
      const sub_sidebar_items = selected_sidebar_items?.['sources'] ?? [];
      const filteredMenuItems = menuItems?.filter(item => sub_sidebar_items?.map(item => item?.charAt(0)?.toUpperCase() + item?.slice(1))?.includes(item?.label));
      filteredMenuItems.forEach(item => { item.engine_name = selected_engine_name });
      items = filteredMenuItems;
    }
    return items;
  };

  const handleMenuItemClick = (index, label, engine) => {
    setSelectedMenuItem(index);
    setCurrentSource(label)
    setCurrentEngine(engine)
  };

  return (

    <nav style={Styles.sidebar}>
      <ul className="menu">
        {filteredMenuItems()?.map((item, index) => (
          <li key={index}
            className={`datasource-menu-item ${selectedMenuItem === index ? 'active' : ''}`}
            onClick={() => handleMenuItemClick(index, item.label, item.engine_name)}
          >
            <span className="datasource-icon">{
              item.icon_type === "svg" ?
                (<ReactSVG
                  src={item.icon}
                  className={`sidebar_icon ${selectedMenuItem === index ? 'active' : ''}`}
                />) :
                (<>{item.icon}</>)
            }
            </span>
            <Typography className="datasource-label" variant="caption">{item.label}</Typography>
          </li>
        ))}

      </ul>
    </nav >
  );
}

export default SubSidebar;