export const Styles = {
    sidebar: {
        height: '100vh',
        width: '180px',
        background: '#F5F7FB',
        position: 'fixed',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
        color: '#000000',
        flexShrink: 0
    },
    componentContainer: {
        backgroundColor: '#FFFFFF',
        flex: 1,
        overflow: 'auto',
        marginTop: '0',
        marginLeft: '180px',
        padding: '20px',
    }
}