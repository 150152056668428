import globalstyes from "../../styles/globalstyes.json";

export const Styles = {
    mainContainer: {
        width: '100%',
        height: '100vh',
        // overflow: 'auto'
    },
    sidebar: {
        width: '180px',
        height: '100vh',
        flexShrink: 0,
        position: 'fixed'
    },
    componentContainer: {
        backgroundColor: '#FFFFFF',
        marginLeft: '180px',
        padding: '20px'
    }
}