export const Styles = {
    // Chat Drawer
    chatBoxOuterContainer: {
        display: 'flex',
        flex: 1,
        height: '100%',
        width: '100%',
        flexGrow: 1,
        overflow: 'auto',
        backgroundColor: '#DEDEDE'
    },
    chatBoxInnerContainer: {
        width: '100%',
        padding: '15px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: '0px'
    },
    chatFeedBackContainer: {
        padding: '15px',
        paddingTop: '0px',
        display: 'flex',
        justifyContent: 'end',
        flexDirection: 'row',
        flex: 1
    },
    chatBoxMessageContainer: {
        padding: '12px 15px',
        borderRadius: '10px',
        maxWidth: '100%',
        wordWrap: 'break-word',
        display: 'flex',
        alignSelf: 'flex-start',
        margin: '0px 0px 15px 20px',
        width: '100%',
        color: '#000',
        backgroundColor: '#FFFFFF'
    },
    chatMessages: {
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    chatInputContainer: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#DEDEDE',
        zIndex: 10,
        padding: '10px',
        display: 'flex'
    }

}
