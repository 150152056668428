import { Box, Button, ButtonBase, IconButton, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Styles } from './Styles';
import { fontStyles } from '../../../styles/fontStyles'
import { searchBarStyles } from '../../../styles/searchBarStyles';
import { Add, AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import { buttonStyles } from '../../../styles/buttonStyles';

function DataSourceSightMaps() {
  return (
    <Box sx={Styles.mainContainer}>

      <Typography sx={fontStyles.largeText}>Sources - Sitemap</Typography>
      <Typography sx={fontStyles.smallText}>Add your website URL and fetch all links</Typography>

      <Box marginTop={'20px'}>
        <Typography sx={fontStyles.smallText}>Website URL</Typography>

        <Box marginTop={'20px'}>
          <Box display={'flex'} alignItems={'center'}>
            <TextField
              id="app-name"
              variant="outlined"
              placeholder='URL'
              size='small'
              sx={{ ...searchBarStyles.searchBar, width: '40%' }}
            />

            <Button sx={{ ...buttonStyles.primaryBtn, width: '150px', marginLeft: '10px' }}>
              <Typography sx={fontStyles.btnText}>Fetch Sitemap</Typography>
            </Button>

          </Box>

        </Box>

      </Box>

    </Box>
  )
}

export default DataSourceSightMaps