import React, { useState } from 'react'
import { Styles } from './Styles';
import { Typography } from '@mui/material';
import { AirSharp, SettingsAccessibility } from '@mui/icons-material';

import { IconSvg } from '../../../utils/globalIcons';
import { ReactSVG } from "react-svg";

function SubSidebar({ setCurrentSettings }) {
    const [selectedMenuItem, setSelectedMenuItem] = useState(0);

    const menuItems = [
        { icon: IconSvg.genralSettingsIcon, label: 'Genral' },
        { icon: IconSvg.llmIcon, label: 'LLM' },
        { icon: IconSvg.keyIcon, label: 'API Keys' }
    ];

    const handleMenuItemClick = (index, label) => {
        setSelectedMenuItem(index);
        setCurrentSettings(label)
    };

    return (
        <nav style={Styles.sidebar}>
            <ul className="menu">
                {menuItems.map((item, index) => (
                    <li key={index}
                        className={`datasource-menu-item ${selectedMenuItem === index ? 'active' : ''}`}
                        onClick={() => handleMenuItemClick(index, item.label)}
                    >
                        <span className="datasource-icon">
                            <ReactSVG
                                src={item.icon}
                                className={`sidebar_icon ${selectedMenuItem === index ? 'active' : ''}`}
                            />
                        </span>
                        <Typography className="datasource-label" variant="caption">{item.label}</Typography>
                    </li>
                ))}

            </ul>
        </nav>
    )
}

export default SubSidebar;