import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Styles } from './Styles';

import SubSidebar from './SubSidebar/SubSidebar';
import GenralSettings from './GenralSettings/GenralSettings';
import AiLLMSettings from './LLMSettings/AiLLMSettings';
import APiKeys from './APIKeys/APiKeys';

function SettingsHome() {
    const [currentSettings, setCurrentSettings] = useState('Genral')

    const renderSettings = () => {
        switch (currentSettings) {
            case 'Genral':
                return <GenralSettings />;
            case 'LLM':
                return <AiLLMSettings />;
            case 'API Keys':
                return <APiKeys />
        }
    };

    return (
        <Box sx={Styles.mainContainer}>
            <SubSidebar setCurrentSettings={setCurrentSettings} />
            <main style={Styles.componentContainer}>
                {renderSettings()}
            </main>
        </Box>
    )
}

export default SettingsHome;