import { Box, Typography } from '@mui/material'
import React from 'react'
import { Styles } from './Style';
import { fontStyles } from '../../styles/fontStyles';

function InsightsHome() {
    return (
        <Box sx={Styles.mainContainer}>
            <Box padding={'20px'}>
                <Typography sx={fontStyles.primaryText}>
                    Project Insights
                </Typography>
            </Box>
        </Box>
    )
}

export default InsightsHome;