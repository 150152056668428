import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    workspace_added: false,
    project_added: false,
    projects_source_folder_added: false,
    coa_files_added: false,
    specification_files_added: false,
    doc_search_files_added: false
};
const newDataSlice = createSlice({
    name: 'new_data',
    initialState,
    reducers: {
        setWorkspaceAddedAdded(state, action) {
            state.workspace_added = action.payload;
        },
        setProjectAdded(state, action) {
            state.project_added = action.payload;
        },
        setProjectSourceFolderAdded(state, action) {
            state.projects_source_folder_added = action.payload;
        },
        setCOAFilesAdded(state, action) {
            state.coa_files_added = action.payload;
        },
        setSpecificationFilesAdded(state, action) {
            state.specification_files_added = action.payload;
        },
        setDocSearchFilesAdded(state, action) {
            state.doc_search_files_added = action.payload;
        }
    }
});

export const {
    setWorkspaceAddedAdded,
    setProjectAdded,
    setProjectSourceFolderAdded,
    setCOAFilesAdded,
    setSpecificationFilesAdded,
    setDocSearchFilesAdded
} = newDataSlice.actions;

export default newDataSlice.reducer;