import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box, Typography, MenuItem, Select,
    IconButton,
    Menu,
    Chip,
    Avatar
} from '@mui/material';
import { Styles } from './Styles';

import { AccountCircle, ArrowDropDown, Logout } from '@mui/icons-material';
import { all_spaces } from "../../data/data";
import getSpaceData from '../../utils/Data/SpaceData';

import { setCurrentSpace } from "../../Redux/slice/currentSpaceSlice";
import { setCurrentProject } from "../../Redux/slice/currentProjectSlice";
import { setSpacesState } from "../../Redux/slice/dataSlice";
import { setSelectedProject } from "../../Redux/slice/selectedDataSlice";
import { handleSidebarConfig } from "../../utils/handleSidebarConfig"

import ameya_logo from "../../images/ameya_nav_logo.png"
import { fontStyles } from '../../styles/fontStyles';
import { getUserInitials, getUserName } from '../../utils/GetAccountDetails';


function Navbar() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const current_space = useSelector(state => state.current_space)
    const current_project = useSelector(state => state.current_project)
    const selected_project = useSelector(state => state.selected_data.selected_project)
    const all_spaces = useSelector(state => state.all_data.spaces)
    const all_projects = useSelector(state => state.all_data.projects)

    const [spaces, setSpaces] = useState([])
    const [projects, setProjects] = useState([])

    const [spacesmenu, setSpacesMenu] = useState(null);
    const [projectsmenu, setProjectsMenu] = useState(null);

    const [logoutmenu, setLogoutMenu] = useState(null);

    const handleClick = (event) => {
        setLogoutMenu(event.currentTarget)
    }
    const handleClose = () => {
        setLogoutMenu(null)
    }

    const handleLogout = () => {
        localStorage.clear()
        navigate("/login")
    };

    useEffect(() => {
        getSpaceDetails()
    }, [all_spaces])

    const getSpaceDetails = async () => {
        try {
            let response
            if (all_spaces?.length > 0) {
                response = all_spaces
            } else {
                response = await getSpaceData()
                dispatch(setSpacesState(response))
            }
            const filterd_spaces = response?.map(space => space?.payload?.workspace_name?.en)
            setSpaces(filterd_spaces)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (current_project) {
            const filterd_space_id = all_spaces?.filter(space => space?.payload?.workspace_name?.en === current_space).map(space => space?.payload?.__auto_id__)?.[0] ?? null;
            const filterd_projects = all_projects?.filter(project => project?.payload?.ameya_workspacess?.includes(filterd_space_id))
                .map(project => {
                    const project_update_key = project?.update_key; return { ...project?.payload, update_key: project_update_key }
                })
            setProjects(filterd_projects)
        }
    }, [all_spaces, all_projects, current_space, current_project])

    useEffect(() => {
        if (current_space) {
            dispatch(setCurrentSpace(current_space))
            dispatch(setCurrentProject(null))
            dispatch(setSelectedProject([]))
            setSpacesMenu(current_space)
        }
    }, [current_space])

    useEffect(() => {
        if (current_space && current_project && selected_project) {
            setProjectsMenu(current_project)
        }
    }, [current_project, selected_project])

    const handleSpaceChange = (e) => {
        const selected_Space = spaces?.find(space => space === e.target.value);
        setSpacesMenu(selected_Space || null);
        dispatch(setCurrentSpace(selected_Space))
        dispatch(setCurrentProject(null))
        navigate(`/spaces/${selected_Space}/projects`)
    }

    const handleProjectChange = (e) => {
        const selected_Project = projects?.find(project => project?.project_name?.en === e.target.value) ?? null;
        if (selected_Project) {
            setProjectsMenu(selected_Project?.project_name?.en || null);
            dispatch(setCurrentProject(selected_Project?.project_name?.en))
            dispatch(setSelectedProject(selected_Project))
            handleSidebarConfig(selected_Project, current_space, current_project, navigate)
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <nav style={Styles.navbar}>
                <Box sx={Styles.navLeftContent}>
                    <Box>
                        <Link to="/">
                            <img src={ameya_logo} alt="Upperline Health Logo" style={{ ...Styles.navbarBrand }} />
                        </Link>
                    </Box>

                    {current_space !== null && (<Box sx={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                        <Select
                            size='small'
                            sx={{
                                border: 'none',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }}
                            value={spacesmenu || ""}
                            displayEmpty
                            onChange={(e) => handleSpaceChange(e)}
                        >
                            <MenuItem value="" disabled>
                                <Typography>All Spaces</Typography>
                            </MenuItem>

                            {spaces && spaces.length > 0 ? spaces.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    <Typography>{item}</Typography>
                                </MenuItem>
                            )) :
                                (<MenuItem disabled>
                                    <Typography>No Spaces Available</Typography>
                                </MenuItem>)
                            }
                        </Select>
                    </Box>)
                    }

                    {(current_space !== null && current_project !== null) &&
                        (<Box sx={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
                            <Select
                                size='small'
                                sx={{
                                    border: 'none',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }}
                                value={projectsmenu || ""}
                                displayEmpty
                                onChange={(e) => handleProjectChange(e)}
                            >
                                <MenuItem value="" disabled>
                                    <Typography>All Projects</Typography>
                                </MenuItem>

                                {projects && projects.length > 0 ? projects.map((item, index) => (
                                    <MenuItem key={index} value={item?.project_name?.en}>
                                        <Typography>{item?.project_name?.en}</Typography>
                                    </MenuItem>
                                )) :
                                    (<MenuItem disabled>
                                        <Typography>No Projects Available</Typography>
                                    </MenuItem>)
                                }
                            </Select>
                        </Box>)
                    }
                </Box>

                <Box sx={Styles.navRightContent}>
                    <Box>
                        <Chip
                            avatar={
                                <Avatar sx={{ bgcolor: '#000000', height: '32px', width: '32px' }}>
                                    <Typography
                                        sx={{ ...fontStyles.smallText, color: '#ffffff' }}
                                        onClick={handleClick}
                                    >
                                        {getUserInitials()}
                                    </Typography>
                                </Avatar>
                            }
                            label={<div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={fontStyles.mediumText}>
                                    {getUserName()}
                                </Typography>
                                <ArrowDropDown
                                    sx={{ cursor: 'pointer', ml: 1 }}
                                    onClick={handleClick}
                                />
                            </div>}
                            sx={{
                                padding: '8px 7px',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '20px',
                            }}
                        />

                        <Box>
                            <Menu
                                anchorEl={logoutmenu}
                                open={logoutmenu}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => handleLogout()} sx={{ ...fontStyles.smallText, dispaly: 'flex', alignItems: 'center' }}>
                                    <Typography>Logout&nbsp;</Typography>
                                    <Logout />
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Box>

            </nav>
        </Box>
    )
}

export default Navbar