import AxiosAmeyaObj from "../../Configurations/axios-ameya-setup";
import { fetchAccountId, fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails"

class ameyaBackendApi {

    constructor() {
        this.accountId = null;
        this.subscribeId = null;
        this.subscriptionId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
        this.subscribeId = await fetchSubscriberId();
        this.subscriptionId = await fetchSubscriptionId();
    }


    addPdfFileDetails = async (reqObj) => {
        await this.initialize();
        return AxiosAmeyaObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/event/qms_add_audit_artifact`, reqObj)
    }

    getPdfDataSource = async () => {
        await this.initialize();
        return AxiosAmeyaObj.get(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/event/qms_get_source_artifacts?page_size=10&current_page=1`)
    }

    addJsonFileDetails = async (reqObj) => {
        await this.initialize();
        return AxiosAmeyaObj.post(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/event/qms_add_spec`, reqObj)
    }

    getJsonDataSource = async () => {
        await this.initialize();
        return AxiosAmeyaObj.get(`/subscriber/${this.subscribeId}/subscription/${this.subscriptionId}/event/qms_get_spec_artifacts?page_size=10&current_page=1`)
    }

}

const DocumentsApi = new ameyaBackendApi();
export default DocumentsApi;


