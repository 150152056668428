import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { ReactSVG } from 'react-svg';

import {
    Box, Button, CircularProgress, InputAdornment, MenuItem, Pagination, Paper, Select, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Tooltip, Typography
} from '@mui/material';
import { Search } from '@mui/icons-material';

import { Styles } from './Styles';
import { fontStyles } from '../../../styles/fontStyles';
import { tableStyles } from '../../../styles/tableStyles';

import { fetchTenentId, fetchUserId } from '../../../utils/GetAccountDetails';
import DocumentSearchApi from '../../../Api/Services/DocumentSearch/HandleFilesApi';
import { buttonStyles } from '../../../styles/buttonStyles';
import LoadBar from '../../../utils/LoadBar';
import { IconSvg } from '../../../utils/globalIcons';

function DocSearchPlayground() {

    const [docSearchItem, setDocSearchItem] = useState('');
    const [docSearchData, setDocSearchData] = useState([])
    const [docSearchLoading, setDocSearchLoading] = useState(false)
    const [searchDatarowsPerPage, setSearchDataRowsPerPage] = useState('5');
    const [searchDataPage, setSearchDataPage] = useState(1);
    const [totalSearchDataPages, setTotalSearchDataPages] = useState(1);

    // Redux States
    const selected_project = useSelector(state => state.selected_data.selected_project)

    const handleDocumentSearch = async (searchQuery) => {
        if (!searchQuery.trim()) {
            setDocSearchData([]);
            return;
        }

        try {
            setDocSearchLoading(true)
            const userId = await fetchUserId();
            const tenentId = await fetchTenentId();

            const reqObj = {
                "query": searchQuery,
                "user_id": userId,
                "tenent_id": tenentId,
                "project_id": selected_project?.__auto_id__,
                "state": "Draft"
            }

            const response = await DocumentSearchApi.documentSearchQuery(JSON.stringify(reqObj));
            if (response.status === 200) {
                const responseData = response?.data
                const result = responseData?.result ?? []
                const updatedData = result?.length > 0 ? result?.map(item => {
                    const file_format = item?.document_name?.includes('.')
                        ? item?.document_name?.split('.')?.pop()
                        : null;
                    return { ...item, file_format };
                }) : []
                setDocSearchData(updatedData)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setDocSearchLoading(false)
        }
    }

    const handleDocSearchChange = (e) => {
        setDocSearchItem(e.target.value);
    }


    // const debouncedSearch = debounce((searchQuery) => {
    //     handleDocumentSearch(searchQuery);
    // }, 2000);

    // const handleDocSearchChange = (e) => {
    //     const query = e.target.value;
    //     setDocSearchItem(query);
    //     debouncedSearch(query);
    // };

    useEffect(() => {
        setTotalSearchDataPages(Math.ceil(docSearchData?.length / parseInt(searchDatarowsPerPage)));
    }, [docSearchData, searchDatarowsPerPage]);

    let PaginatedDocSearchData = [];
    const handleSearchDataPage = (event, newPage) => { setSearchDataPage(newPage) }

    const handleSearchDataRowsPerPageChange = event => {
        setSearchDataRowsPerPage(event.target.value);
        const newRowsPerPage = event.target.value;
        const newTotalPages = Math.ceil(docSearchData?.length / parseInt(newRowsPerPage));
        setSearchDataRowsPerPage(newRowsPerPage);
        setSearchDataPage(prevPage => (prevPage <= newTotalPages ? prevPage : newTotalPages));
        setTotalSearchDataPages(newTotalPages);
    };
    if (docSearchData?.length > 0) {
        const startIndex = (searchDataPage - 1) * parseInt(searchDatarowsPerPage);
        const endIndex = Math.min(startIndex + parseInt(searchDatarowsPerPage), docSearchData?.length);
        PaginatedDocSearchData = docSearchData?.slice(startIndex, endIndex);
    }


    return (
        <Box sx={Styles.mainContainer}>
            {docSearchLoading && <LoadBar />}

            <Typography sx={fontStyles.largeText}>
                Playground
            </Typography>
            <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                Test Doc Search project
            </Typography>

            <Box marginTop={'20px'} display={'flex'} alignItems={'center'}>
                <TextField
                    placeholder='Search'
                    variant="outlined"
                    size='small'
                    sx={{ width: '35%' }}
                    value={docSearchItem}
                    onChange={handleDocSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    sx={{ ...buttonStyles.primaryBtn, width: '100px', marginLeft: '10px' }}
                    disabled={docSearchLoading}
                    onClick={() => handleDocumentSearch(docSearchItem)}
                >
                    <Typography sx={fontStyles.btnText}>Search</Typography>
                </Button>
            </Box>

            <Box marginTop={'30px'} paddingBottom={'20px'}>
                <Paper>
                    < TableContainer component={Paper} sx={tableStyles.tcontainer}>
                        <Table
                            stickyHeader sx={{ height: PaginatedDocSearchData.length <= 0 ? '400px' : `${PaginatedDocSearchData.length}px` }}
                        >
                            <TableHead sx={tableStyles.thead}>
                                <TableRow>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '30%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.productionfile_name.en'}
                                        // direction={productionfilesortKey === 'payload.productionfile_name.en' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.productionfile_name.en')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Document</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '70%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.created_on'}
                                        // direction={productionfilesortKey === 'payload.created_on ' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.created_on')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Relevance Score</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    PaginatedDocSearchData?.length === 0 && docSearchLoading ?
                                        (<TableRow sx={tableStyles.emptytr}>
                                            <TableCell colSpan={7} sx={tableStyles.emptytdcell}>
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>)
                                        :
                                        (PaginatedDocSearchData?.length > 0 && PaginatedDocSearchData?.map((item, index) => (
                                            < TableRow key={index} sx={tableStyles.trow}>
                                                <TableCell style={tableStyles.tdcell}>

                                                    <Box display={'flex'} alignItems={'center'}>
                                                        <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                                                            {item?.file_format === "pdf" && <ReactSVG src={IconSvg.pdfIcon} />}
                                                            {item?.file_format === "docx" && <ReactSVG src={IconSvg.docIcon} />}
                                                            {item?.file_format === "txt" && <ReactSVG src={IconSvg.txtIcon} />}
                                                            {item?.file_format === "pptx" && <ReactSVG src={IconSvg.pptIcon} />}
                                                            {item?.file_format !== "pdf" && item?.file_format !== "txt" && item?.file_format !== "docx" &&
                                                                item?.file_format !== "pptx" && <ReactSVG src={IconSvg.filesIcon} />}
                                                        </span>
                                                        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                            <Tooltip placement='right-start'
                                                                title={
                                                                    <div style={{ padding: "10px" }}>
                                                                        <p>{item?.document_name}</p>
                                                                    </div>
                                                                }
                                                                arrow PopperProps={{
                                                                    style: { width: '250px' }
                                                                }}>
                                                                {item?.document_name?.length > 30 ? `${item?.document_name?.substring(0, 70)}...` : item?.document_name}
                                                            </Tooltip>
                                                        </span>
                                                    </Box>
                                                </TableCell>
                                                <TableCell style={tableStyles.tdcell}>{item?.relavence_score}%</TableCell>
                                            </TableRow>))
                                        )
                                }
                                {!docSearchLoading && PaginatedDocSearchData?.length === 0 && (
                                    <TableRow sx={tableStyles.emptytr}>
                                        <TableCell colSpan={8} sx={tableStyles.emptytdcell}>
                                            No records to display
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer >

                    {docSearchData?.length > 0 &&
                        (<Box display={'flex'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box marginLeft={'20px'}>
                                    <Select
                                        value={searchDatarowsPerPage}
                                        onChange={handleSearchDataRowsPerPageChange}
                                        sx={{ width: '110px', height: '35px' }}
                                    >
                                        <MenuItem value={'5'}>5 rows</MenuItem>
                                        <MenuItem value={'10'}>10 rows</MenuItem>
                                        <MenuItem value={'15'}>15 rows</MenuItem>
                                        <MenuItem value={'20'}>20 rows</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <Pagination
                                count={totalSearchDataPages}
                                page={searchDataPage}
                                onChange={handleSearchDataPage}
                                color="standard"
                            />
                        </Box>)
                    }

                </Paper>
            </Box>

        </Box>


    )
}

export default DocSearchPlayground;