import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    spaces: [],
    projects: [],
    project_source_folders: [],
    coafiles: [],
    specificationfiles: [],
    docsearchfiles: []
};

const dataSlice = createSlice({
    name: 'all_data',
    initialState,
    reducers: {

        // Spaces
        setSpacesState(state, action) {
            state.spaces = action.payload;
        },

        // Projects
        setProjectsState(state, action) {
            state.projects = action.payload;
        },

        // SourceFiles
        setProjectSourceFolderState(state, action) {
            state.project_source_folders = action.payload;
        },
        setCOAFilesState(state, action) {
            state.coafiles = action.payload;
        },
        setSpecificationFilesState(state, action) {
            state.specificationfiles = action.payload;
        },

        // Doc Search Files
        setDocSearchFilesState(state, action) {
            state.docsearchfiles = action.payload;
        }
    },
});

export const {
    setSpacesState,
    setProjectsState,
    setProjectSourceFolderState,
    setCOAFilesState,
    setSpecificationFilesState,
    setDocSearchFilesState
} = dataSlice.actions;

export default dataSlice.reducer;
