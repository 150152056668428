import { createSlice } from "@reduxjs/toolkit";

const initialState = null;
const currentSpaceSlice = createSlice({
    name: 'current_space',
    initialState,
    reducers: {
        setCurrentSpace(state, action) {
            return action.payload;
        },
    }
});

export const { setCurrentSpace } = currentSpaceSlice.actions;
export default currentSpaceSlice.reducer;