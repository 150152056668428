import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    projects: []
};

const projectSlice = createSlice({
    name: 'project_data',
    initialState,
    reducers: {
        setProjects(state, action) {
            state.projects = action.payload;
        }
    },
});

export const { setProjects } = projectSlice.actions;

export default projectSlice.reducer;
