import { configureStore } from "@reduxjs/toolkit";
import currentSpaceReducer from "../slice/currentSpaceSlice";
import currentProjectReducer from "../slice/currentProjectSlice";
import projectReducer from "../slice/projectsSlice";
import allDataReducer from "../slice/dataSlice";
import selectedDataReducer from "../slice/selectedDataSlice";
import newDataReducer from "../slice/newDataSlice";


const store = configureStore(
    {
        reducer: {
            current_space: currentSpaceReducer,
            current_project: currentProjectReducer,
            selected_data: selectedDataReducer,

            all_data: allDataReducer,
            data_added: newDataReducer,

            projects: projectReducer
        },
    }
)

export default store;