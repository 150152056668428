import globalstyes from "../../../styles/globalstyes.json";

export const Styles = {
    mainContainer: {
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        padding: '20px'
    },
    datasourceContainer: {
        marginRight: '20px',
        textAlign: 'center',
    },
    datasource: {
        cursor: 'pointer',
        width: '100px',
        height: '100px',
        bgcolor: 'white',
        border: '1px solid #D9D9D9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        marginRight: '30px',
        textAlign: 'center',
        backgroundColor: '#F5F7FB'
    }

}