import React from 'react';
import { Styles } from './Styles';
import { ReactSVG } from "react-svg";
import { mainStyles } from '../../styles/styles';
import { fontStyles } from '../../styles/fontStyles';
import { IconSvg } from '../../utils/globalIcons';
import { getUserInitials } from '../../utils/GetAccountDetails';
import { SendOutlined } from '@mui/icons-material';
import { Box, TextField, Typography, SwipeableDrawer, IconButton, InputAdornment, Avatar, } from '@mui/material';

function ChatHandler({ chatdrawer, toggleDrawer, chatWindowRef, qmsChatData, setQmsChat, newQmsChat, handleQmsChat, qmsSystemResponse }) {
    return (
        <SwipeableDrawer
            anchor='right'
            open={chatdrawer.state}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            sx={{
                height: '100vh',
            }}
        >
            <Box sx={mainStyles.sideDrawerContainer}>
                <Typography style={fontStyles.drawerHeading}>Train AI QMS Agent</Typography>
                <Box sx={Styles.chatBoxOuterContainer}>
                    <Box ref={chatWindowRef} sx={Styles.chatBoxInnerContainer}>
                        {qmsChatData.map((msg, index) => (
                            <Box key={index} display={'flex'} width={'100%'}>

                                {msg.sender === 'user' &&
                                    <Box width={'20px'} marginTop={'5px'}>
                                        <Avatar sx={{ bgcolor: '#000000', height: '32px', width: '32px' }}>
                                            <Typography sx={fontStyles.mediumText}>{getUserInitials()}</Typography>
                                        </Avatar>
                                    </Box>}
                                {msg.sender === 'system' &&
                                    <Box width={'20px'} marginTop={'5px'}>
                                        <Box sx={mainStyles.ameya_icon}>
                                        </Box>
                                    </Box>
                                }
                                <Box
                                    sx={Styles.chatBoxMessageContainer}
                                >
                                    <Typography
                                        sx={{
                                            ...fontStyles.smallText,
                                            ...Styles.chatMessages
                                        }}
                                    >
                                        {msg.chat}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                        <Box sx={Styles.chatFeedBackContainer}>
                            {qmsSystemResponse.next_user_action_type === "text or feedback_thumps_up or feedback_options, or restart" &&
                                <Box>
                                    <IconButton
                                        sx={{ backgroundColor: '#0B51C5' }}
                                        onClick={() => setQmsChat({ ...newQmsChat, feed_back: true, feedback_type: 'feedback_thumps_up' })}
                                    >
                                        <ReactSVG src={IconSvg.thumbupIcon} className='thumbsup_icon' />
                                    </IconButton>
                                    <IconButton
                                        sx={{ backgroundColor: '#0B51C5' }}
                                        onClick={() => setQmsChat({ ...newQmsChat, feed_back: true, feedback_type: 'feedback_thumps_down' })}
                                    >
                                        <ReactSVG src={IconSvg.thumbdownIcon} className='thumbsdown_icon' />
                                    </IconButton>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>

                <Box sx={Styles.chatInputContainer}>
                    <TextField
                        placeholder='Write your message'
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={newQmsChat.chat}
                        disabled={qmsSystemResponse.block_user_text_input || newQmsChat.disable_chat || qmsSystemResponse.next_user_action_type === 'feedback'}
                        onChange={(e) => setQmsChat({ ...newQmsChat, chat: e.target.value })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        color="primary"
                                        onClick={handleQmsChat}
                                        disabled={!newQmsChat?.chat?.trim() || newQmsChat.disable_chat || qmsSystemResponse.block_user_text_input || qmsSystemResponse.next_user_action_type === 'feedback'}
                                    >
                                        <SendOutlined />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

            </Box>
        </SwipeableDrawer >
    )
}

export default ChatHandler;