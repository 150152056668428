import { Autocomplete, Box, Button, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { Styles } from './Styles'
import { fontStyles } from '../../../styles/fontStyles'
import { searchBarStyles } from '../../../styles/searchBarStyles'
import { buttonStyles } from '../../../styles/buttonStyles';

function AiLLMSettings() {

    const LLM_DATA = [
        { 'name': 'Claude 3.5' },
        { 'name': 'Claude 3.53' },
        { 'name': 'Claude 3.5e' },
        { 'name': 'Claude 3.5r' }
    ]
    return (
        <Box sx={Styles.mainContainer}>

            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography sx={fontStyles.largeText}>LLM</Typography>

                <Box display={'flex'} alignItems={'center'}>
                    <Button sx={{ ...buttonStyles.primaryBtn, width: '100px' }}>
                        SAVE
                    </Button>
                    <Button sx={{ ...buttonStyles.secondaryBtn, width: '100px', marginLeft: '10px' }}>
                        CANCEL
                    </Button>
                </Box>

            </Box>

            <Typography sx={{ ...fontStyles.smallText, marginTop: '10px' }}>
                Select LLM model to power your app
            </Typography>

            <Box marginTop={'20px'}>
                <FormControl>
                    <Typography sx={{ ...fontStyles.smallText, marginBottom: '6px' }}>Select LLM Model</Typography>
                    <Autocomplete
                        sx={{ ...searchBarStyles.searchBar, width: '300px' }}
                        options={LLM_DATA}
                        getOptionLabel={(option) => option.name}
                        id="clear-on-escape"
                        clearOnEscape
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="LLM Model" />
                        )}
                    />
                </FormControl>
            </Box>

            <Box marginTop={'20px'}>
                <FormControl>
                    <Typography sx={{ ...fontStyles.smallText, marginBottom: '6px' }}>API Key</Typography>
                    <TextField
                        id="api-key"
                        variant="outlined"
                        placeholder='API Key'
                        size='medium'
                        sx={{ ...searchBarStyles.searchBar, width: '600px' }}
                        name="database_name"
                    // value={databaseData.database_name}
                    // onChange={handleDatabaseDataChange}
                    />
                </FormControl>
            </Box>

        </Box>
    )
}

export default AiLLMSettings;