import AxiosAmeyaObj from "../../Configurations/axios-ameya-doc-search-setup";
import { fetchAccountId, fetchSubscriberId, fetchSubscriptionId } from "../../../utils/GetAccountDetails"

class ameyaBackendApi {

    constructor() {
        this.accountId = null;
        this.subscribeId = null;
        this.subscriptionId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
        this.subscriberId = await fetchSubscriberId();
        this.subscriptionId = await fetchSubscriptionId();
    }

    uploadDraftPdfFile = async (reqObj) => {
        await this.initialize();
        return AxiosAmeyaObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/event/documentsearch/document/draft`, reqObj)
    }
    uploadPublishPdfFile = async (reqObj) => {
        await this.initialize();
        return AxiosAmeyaObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/event/documentsearch/document/published`, reqObj)
    }
    deleteUploadedFiles = async (reqObj) => {
        await this.initialize();
        return AxiosAmeyaObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/event/documentsearch/document/archive`, reqObj)
    }
    fetchUploadedFiles = async (reqObj) => {
        await this.initialize();
        return AxiosAmeyaObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/event/documentsearch/document/get-files`, reqObj)
    }
    documentSearchQuery = async (reqObj) => {
        await this.initialize();
        return AxiosAmeyaObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/event/documentsearch/search`, reqObj)
    }
    fileVersionChange = async (reqObj) => {
        await this.initialize();
        return AxiosAmeyaObj.post(`/subscriber/${this.subscriberId}/subscription/${this.subscriptionId}/event/documentsearch/document/version-change`, reqObj)
    }

}

const DocumentSearchApi = new ameyaBackendApi();
export default DocumentSearchApi;


