import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ReactSVG } from "react-svg";

import {
    Box, Button, CircularProgress, Divider, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TableSortLabel, Tooltip, Typography
} from '@mui/material'
import { CancelOutlined, CheckCircleOutlineOutlined, CloudUpload, DeleteOutlineOutlined, ErrorOutlineOutlined, QueryBuilderOutlined, SignalCellularNullRounded } from '@mui/icons-material';

import { Styles } from './Styles';
import "./Styles.css";
import { fontStyles } from '../../../../styles/fontStyles';
import { tableStyles } from '../../../../styles/tableStyles';
import { buttonStyles } from '../../../../styles/buttonStyles';

import LoadBar from '../../../../utils/LoadBar';
import { tostAlert } from '../../../../utils/AlertToast';
import { AlertMessages } from '../../../../utils/AlertMessages';
import { apiErrorHandler } from '../../../../utils/ApiErrorHandler';
import ProjectSourceFiles from '../../../../utils/Data/Sources/ProjectSourceFiles';
import ProjectParentFolders from '../../../../utils/Data/Sources/ProjectParentFolders';

import FilesApi from '../../../../Api/Services/DataSource/galleryService';

import { setSpecificationFilesState, setProjectSourceFolderState } from "../../../../Redux/slice/dataSlice";
import { setProjectSourceFolderAdded, setSpecificationFilesAdded } from "../../../../Redux/slice/newDataSlice";
import { fetchSubscriberId, fetchSubscriptionId } from '../../../../utils/GetAccountDetails';
import DocumentsApi from '../../../../Api/Services/AmeyaBackendAPI/DocumentsApi';
import { fileExcistenceCheck, validateFileUpload } from '../../../../utils/FileHandling/FileHandling';
import { IconSvg } from '../../../../utils/globalIcons';
import JsonSourceFiles from '../../../../utils/Data/Sources/JsonSourceFiles';

let resJsonVar = []
let uploadProgress = []

function SpecificationFiles() {
    const dispatch = useDispatch()

    // Files Upload
    const filePermission = "PUBLIC"
    const [uploadJson, setUploadJson] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [invalidJsonFiles, setInvalidJsonFiles] = useState([])
    const [uploadProgressState, setUploadProgress] = useState([])
    const [loadingSave, SetLoadingSave] = useState(false)

    const [loading, setLoading] = useState(false);
    const [patentFolderLoading, setParentFolderLoading] = useState(false)
    const [projectsourceparentFolders, setProjectSourceParentFolders] = useState([])


    // specificationfiles Files
    const [specificationfiles, setSpecificationFiles] = useState([]);
    const [specificationfileloading, setSpecificationFilesLoading] = useState(false)

    // Redux States
    const project_source_folders = useSelector(state => state.all_data.project_source_folders)
    const selected_project = useSelector(state => state.selected_data.selected_project)
    const projects_source_folder_added = useSelector(state => state.data_added.projects_source_folder_added)
    const specification_files_added = useSelector(state => state.data_added.specification_files_added)
    const specification_files_data = useSelector(state => state.all_data.specificationfiles)

    useEffect(() => {
        getAllProjectParentFolders()
        getSpecificationFilesDetails()
    }, [projects_source_folder_added, specification_files_added])

    const getAllProjectParentFolders = async () => {
        setParentFolderLoading(true)
        let specification_source_folders = []
        if (project_source_folders?.length > 0 && projects_source_folder_added === false) {
            specification_source_folders = project_source_folders
        } else {
            const response = await ProjectParentFolders()
            dispatch(setProjectSourceFolderState(response))
            dispatch(setProjectSourceFolderAdded(false))
            specification_source_folders = response
        }
        const filtered_source_folders = specification_source_folders?.filter((item) => item?.id === selected_project?.__auto_id__)
        setProjectSourceParentFolders(filtered_source_folders)
        setParentFolderLoading(false)
    }

    const getSpecificationFilesDetails = async () => {
        setSpecificationFilesLoading(true)
        try {
            let spec_files = []
            if (specification_files_data?.length > 0 && specification_files_added === false) {
                spec_files = specification_files_data
            } else {
                const response = await JsonSourceFiles();
                spec_files = response
                dispatch(setSpecificationFilesState(response))
                dispatch(setSpecificationFilesAdded(false))
            }
            const updatedData = spec_files?.length > 0 ? spec_files?.map(item => {
                const fixedDate = item?.created_on?.replace(/:(\d{2}):(\d{2})$/, (match, mins, secs) => {
                    return `:${mins}:${Math.min(Number(secs), 59)}`;
                });
                return { ...item, fixedDate };
            }) : []

            const sorted_spec_files = updatedData?.length > 0
                ? [...updatedData]?.sort((a, b) => {
                    const dateA = Date.parse(a?.fixedDate) ? new Date(a?.fixedDate) : null;
                    const dateB = Date.parse(b?.fixedDate) ? new Date(b?.fixedDate) : null;
                    if (!dateA || !dateB) return !dateA ? 1 : -1;
                    return dateB - dateA;
                })
                : [];
            console.log("sorted_spec_files:", sorted_spec_files)
            setSpecificationFiles(sorted_spec_files)
        } catch (error) {
            console.log(error)
        } finally {
            setSpecificationFilesLoading(false)
        }
    }

    const validateJsonFile = async (files) => {
        const validFiles = [];
        const invalidFiles = [];

        for (const file of files) {
            try {
                const text = await file.text();
                const json = JSON.parse(text);
                validFiles.push({ name: file.name, content: json });
            } catch (error) {
                invalidFiles.push(file.name);
            }
        }
        setInvalidJsonFiles(invalidFiles)
        if (invalidFiles.length > 0) {
            tostAlert(`The following files are not valid JSON:\n${invalidFiles.join("\n")}`, 'custom_warning')
        }
    }

    const handleFileDrop = async (event) => {
        event.preventDefault();
        const allowedExtensions = ['json'];
        const files = Array.from(event.target.files);
        const blockedFiles = await validateFileUpload(files, allowedExtensions);
        event.target.value = null;
        if (blockedFiles.length > 0) {
            tostAlert(`These files were not allowed:\n\n${blockedFiles.join('\n')}`, 'warning')
            return;
        }
        setSelectedFiles(files);
    };

    const handleFileChange = async (event) => {
        event.preventDefault();
        const allowedExtensions = ['json'];
        const files = Array.from(event.target.files);
        const blockedFiles = await validateFileUpload(files, allowedExtensions);
        event.target.value = null;
        if (blockedFiles.length > 0) {
            tostAlert(`These files were not allowed:\n\n${blockedFiles.join('\n')}`, 'warning')
            return;
        }
        setSelectedFiles(files);
    };

    const handleFileRemove = async (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        await validateJsonFile(updatedFiles)
        setSelectedFiles(updatedFiles);
    }

    const markIndexFailed = (index) => {
        const tempLoadArr = [...uploadProgress]
        tempLoadArr[index] = 'failed'
        uploadProgress = tempLoadArr
        setUploadProgress([...tempLoadArr])
    }

    const saveInAmeyaBackend = async (file, fileType, fileId, fileUrl, index) => {
        const reqObj = [
            {
                "file_id": fileId,
                "file_name": file?.name,
                "file_extension": fileType,
                "file_location": fileUrl,
                "created_on": "2024-12-03T06:39:37.184822"
            }
        ]
        await DocumentsApi.addJsonFileDetails(JSON.stringify(reqObj));
        const tempLoadArr = [...uploadProgress]
        tempLoadArr[index] = 'processed'
        uploadProgress = tempLoadArr
        setUploadProgress([...tempLoadArr])
    }

    const uploadFile = async (blob, reqBody) => {
        try {
            const resData = await FilesApi.getPredignedURL(JSON.stringify(reqBody))
            const urlFields = JSON.parse(resData.data.url_fields)
            let formData = new FormData();

            formData.append('key', urlFields.key);
            formData.append('AWSAccessKeyId', urlFields.AWSAccessKeyId);
            formData.append('x-amz-security-token', urlFields['x-amz-security-token']);
            formData.append('policy', urlFields.policy);
            formData.append('signature', urlFields.signature);
            formData.append('file', blob);

            const resUpload = await FilesApi.uploadFile(resData.data.url, formData)
            if (resUpload.status === 200 || resUpload.status === 204) {
                return resData?.data?.file_id
            }
            return null
        } catch (e) {
            console.log('Err ' + e)
            return null
        }
    }

    const uploadAndAppendForPost = async (file, fileType, thumbnailFileId, index, parent_folder) => {
        const reqBodyFile = {
            filecontext: 'bucket_dpod_user_file',
            contentType: file?.name?.split('.')[1],
            filetype: '',
            fileName: file?.name
        }
        const uploadedFileId = await uploadFile(file, reqBodyFile)
        const uploadedFileUrl = await FilesApi.getUploadedFileUrls(uploadedFileId)
        const tempJsonArr = [...resJsonVar]
        const uploadResObj = {
            access_type: filePermission,
            file_type: fileType,
            folder_id: parent_folder,
            file_id: uploadedFileId,
            thumbnail_file_id: thumbnailFileId,
            file_attributes: {
                file_name: file?.name,
                file_url: uploadedFileUrl?.data?.[0]?.url,
                created_on: moment().format("DD-MM-YYYY"),
                file_extension: file.name.split('.')[1]
            }
        }
        tempJsonArr.push(uploadResObj)
        resJsonVar = tempJsonArr
        setUploadJson(tempJsonArr)
        return { "fileId": uploadedFileId, "fileUrl": uploadedFileUrl?.data?.[0]?.url }
    }

    const handleDocumentUpload = async (file, index, parent_folder) => {
        try {
            const { fileId, fileUrl } = await uploadAndAppendForPost(file, 'Json', null, index, parent_folder)
            await saveInAmeyaBackend(file, '.json', fileId, fileUrl, index)
            const tempLoadArr = [...uploadProgress]
            tempLoadArr[index] = 'processed'
            uploadProgress = tempLoadArr
            setUploadProgress([...tempLoadArr])
        }
        catch (err) {
            console.log("ERROR: ", err);
            markIndexFailed(index)
        }
    }

    const handleParentFolder = async (projectParentFolder, current_projectId) => {
        if (projectParentFolder?.length > 0 && projectParentFolder?.[0]?.id) {
            return projectParentFolder?.[0]?.id
        }
        try {
            const folderObj = {
                "folder_name": current_projectId,
                "can_be_subscribed": true,
                "access_type": "PRIVATE",
                "parent_folder_id": null,
                "folder_id": current_projectId
            }
            const folder_response = await FilesApi.createSourceFolders(folderObj)
            if (folder_response.status === 200) {
                dispatch(setProjectSourceFolderAdded(true))
                return folder_response?.data?.folder_id ?? null
            }
            markIndexFailed(0)
        } catch (error) {
            console.log(error)
            markIndexFailed(0)
        }
    }

    const startUploading = async () => {
        if (selectedFiles.length === 0) {
            const message = await AlertMessages('warning', 'Files');
            tostAlert(message, 'warning')
            return
        }

        const file_exists = await fileExcistenceCheck(specificationfiles, selectedFiles)
        if (file_exists) return

        if (invalidJsonFiles.length > 0) {
            tostAlert(`The following files are not valid JSON:\n${invalidJsonFiles.join("\n")}`, 'custom_warning')
            return
        }

        setLoading(true)
        const tempArr = selectedFiles.map(file => 'pending')
        setUploadProgress([...tempArr])
        uploadProgress = tempArr

        // check for the parentfolder of project and if not present create it
        const parent_folder = await handleParentFolder(projectsourceparentFolders, selected_project?.__auto_id__)

        selectedFiles.forEach((file, index) => {
            handleDocumentUpload(file, index, parent_folder)
            const tempLoadArr = [...uploadProgress]
            tempLoadArr[index] = 'uploading'
            uploadProgress = tempLoadArr
            setUploadProgress([...tempLoadArr])
        })
    }

    const closeUploadIndicator = () => {
        setLoading(false)
        setUploadProgress([])
        uploadProgress = []
        resJsonVar = []
        setSelectedFiles([])
    }

    const saveUploadChanges = async () => {
        SetLoadingSave(true)
        try {
            const res = await FilesApi.createFile(uploadJson)
            if (res.status === 200 || res.status === 204) {
                dispatch(setSpecificationFilesAdded(true))
                if (uploadProgressState.length > 1) {
                    tostAlert(uploadProgressState.length + " files uploaded", 'success')
                } else {
                    tostAlert("File uploaded", "success")
                }
            }
        } catch (error) {
            console.log(error)
            apiErrorHandler(error)
        } finally {
            SetLoadingSave(false)
            setLoading(false)
            uploadProgress = []
            resJsonVar = []
            setUploadProgress([])
            setSelectedFiles([])
        }
    }

    useEffect(() => {
        if (uploadProgressState && uploadProgressState.length && uploadProgressState.length > 0) {
            let i
            let flagToSave = true
            for (i = 0; i < uploadProgressState.length; i++) {
                if (!(uploadProgressState[i] === "processed" || uploadProgressState[i] === "done" || uploadProgressState[i] === "failed")) {
                    flagToSave = false
                    break
                }
            }
            if (flagToSave) {
                saveUploadChanges()
            }
        }
    }, [uploadProgressState])


    return (
        <Box sx={Styles.mainContainer}>

            {(loading || specificationfileloading) && (<LoadBar />)}

            {
                uploadProgressState && uploadProgressState.length > 0
                    ?
                    <Box sx={Styles.uploadLoadingBox}>
                        <Box sx={Styles.uploadingHeaderBox}>
                            {
                                loadingSave
                                    ?
                                    <Typography sx={Styles.uploadingText}>Saving changes</Typography>
                                    :
                                    <Typography sx={Styles.uploadingText}>Uploading files …</Typography>
                            }
                            {
                                !loadingSave ? <CancelOutlined sx={Styles.closeIcon} onClick={closeUploadIndicator} /> : ''
                            }
                        </Box>
                        {
                            loadingSave ? <LinearProgress width="100%" /> : ''
                        }
                        <Box width="100%">
                            {
                                selectedFiles.map((file, index) =>
                                    <Box key={"upload_progress_ind_key_" + index}>
                                        {
                                            index > 0 ? <Divider width="100%" /> : ''
                                        }
                                        <Box display="flex" height="40px" width="100%" alignItems="center" paddingX="10px">
                                            {
                                                ({
                                                    'uploading': <CircularProgress size="18px" sx={Styles.tickIcon} color="primary" />,
                                                    'processed': <CheckCircleOutlineOutlined sx={Styles.tickIcon} color="primary" />,
                                                    'pending': <QueryBuilderOutlined sx={Styles.tickIcon} color="primary" />,
                                                    'done': <CheckCircleOutlineOutlined sx={Styles.tickIcon} color="primary" />,
                                                    'failed': <ErrorOutlineOutlined sx={Styles.failedIcon} />
                                                }[uploadProgressState[index]])
                                            }
                                            <Typography noWrap sx={Styles.dropZoneFont}>{file?.name}</Typography>
                                        </Box>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                    :
                    ''
            }

            <Box sx={Styles.fileUploadContainer}>
                <Box
                    onDrop={handleFileDrop}
                    onDragOver={(e) => e.preventDefault()}

                >
                    <input
                        type="file"
                        accept="*"
                        onChange={handleFileChange}
                        style={{ display: 'none', cursor: 'pointer' }}
                        id="fileInput"
                        multiple
                    />
                    <label htmlFor="fileInput">
                        {selectedFiles.length === 0 &&
                            <Box>
                                <Typography sx={fontStyles.mediumText}>Drag and drop files or&nbsp;<span style={{ color: '#0B51C5', cursor: 'pointer' }}>click to upload</span> </Typography>
                                <Typography sx={fontStyles.smallText}>Upto 50 MB in size (.json) </Typography>
                            </Box>
                        }
                        <IconButton component="span">
                            <CloudUpload style={{ color: "black", width: "32px", height: "32px" }} />
                        </IconButton>
                    </label>
                </Box>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    {
                        selectedFiles.length > 0 && selectedFiles.map((file, index) => (
                            < Box key={index} display={'flex'} justifyContent={'center'} alignItems={'center'} alignContent={'center'} textAlign={'center'} padding={'3px 15px 3px 15px'} margin={'5px'} backgroundColor={'#FFFFFF'} borderRadius={'20px'}>
                                <Typography sx={fontStyles.smallText}>{file.name}</Typography>
                                <Box marginLeft={'10px'} onClick={() => handleFileRemove(index)}>
                                    <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                </Box>
                            </Box>
                        ))
                    }

                </Box>
            </Box >

            <Box marginTop={'20px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Button
                    sx={{ ...buttonStyles.primaryBtn, width: '180px', height: '40px' }}
                    disabled={patentFolderLoading}
                    onClick={() => startUploading()}
                >
                    <Typography style={fontStyles.btnText}>Upload and Train</Typography>
                </Button>
            </Box>

            <Box marginTop={'30px'} paddingBottom={'20px'}>
                <Paper>
                    < TableContainer component={Paper} sx={tableStyles.tcontainer}>
                        <Table
                        // stickyHeader sx={{ height: paginated_productionfile_list.length <= 0 ? '400px' : `${paginated_productionfile_list.length}px` }}
                        >
                            <TableHead sx={tableStyles.thead}>
                                <TableRow>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '45%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.productionfile_name.en'}
                                        // direction={productionfilesortKey === 'payload.productionfile_name.en' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.productionfile_name.en')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Document</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '45%' }}>
                                        <TableSortLabel sx={tableStyles.sortlabel}
                                        // active={productionfilesortKey === 'payload.created_on'}
                                        // direction={productionfilesortKey === 'payload.created_on ' ? productionfilesortDirection : 'asc'}
                                        // onClick={() => handleProductionFileSort('payload.created_on')}
                                        >
                                            <Typography sx={tableStyles.theadText}>Created On</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={{ ...tableStyles.thcell, width: '10%' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {specificationfiles?.length === 0 && specificationfileloading ?
                                    (<TableRow sx={tableStyles.emptytr}>
                                        <TableCell colSpan={7} sx={tableStyles.emptytdcell}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>)
                                    :
                                    (specificationfiles?.length > 0 && specificationfiles?.map((item, index) => (
                                        < TableRow key={index} sx={tableStyles.trow}>
                                            <TableCell style={tableStyles.tdcell}>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                                                        <ReactSVG className='rag_file_icon' src={IconSvg.filesIcon} />
                                                    </span>
                                                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        <Tooltip placement='right-start'
                                                            title={
                                                                <div style={{ padding: "10px" }}>
                                                                    <p>{item?.file_name}</p>
                                                                </div>
                                                            }
                                                            arrow PopperProps={{
                                                                style: { width: '250px' }
                                                            }}>
                                                            {item?.file_name?.length > 30 ? `${item?.file_name?.substring(0, 50)}...` : item?.file_name}
                                                        </Tooltip>
                                                    </span>
                                                </Box>
                                            </TableCell>
                                            <TableCell style={tableStyles.tdcell}>{item?.created_on?.substring(0, 10)}</TableCell>
                                            <TableCell style={tableStyles.tdcell}>
                                                <ReactSVG src={IconSvg.deleteIcon} className='delete_icon' />
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                                {!specificationfileloading && specificationfiles?.length === 0 && (
                                    <TableRow sx={tableStyles.emptytr}>
                                        <TableCell colSpan={8} sx={tableStyles.emptytdcell}>
                                            No records to display
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer >
                </Paper>
            </Box>

        </Box>
    )
}

export default SpecificationFiles