import ameya_logo from '../images/ameya_icon.png';


export const mainStyles = {
    // ComponentRender

    mainContainer: {
        display: 'flex',
        height: '100vh',
        overflow: 'hidden'
    },
    navMainConatainer: {
        marginLeft: '85px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    sidebarContainer: {
        width: '85px',
        height: '100vh',
        flexShrink: 0,
        position: 'fixed'
    },
    mainComponentContainer: {
        backgroundColor: '#FFFFFF',
        flex: 1,
        overflow: 'auto',
        marginTop: '0',
        marginLeft: '0',
        paddingTop: '75px',
        paddingBottom: '20px',
        overflow: 'auto'
    },
    navContainer: {
        position: 'fixed',
        top: 0,
        left: '85px',
        right: 0,
        height: '75px',
        zIndex: 10
    },

    // Cards
    cardHeader: {
        borderRadius: '0',
        boxShadow: 'none',
        fontFamily: 'Inter',
    },
    noRecord: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        height: '100%',
        textAlign: "center"
    },

    // Loadbar
    loadbarContainer: {
        top: '0',
        left: '0',
        width: '100%',
        position: 'fixed',
        zIndex: '9999'
    },
    loadbar: {
        height: 7,
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#007bff'
        }
    },

    // Tabs
    activateTab: {
        '& .MuiTabs-indicator': { backgroundColor: '#0B51C5', height: '3px' },
        '& .Mui-selected': {
            color: '#000000'
        },
    },
    tabs: {
        textTransform: 'capitalize',
        '&.Mui-selected': {
            color: '#000000'
        }
    },

    // Side Drawer
    sideDrawerContainer: {
        width: '530px',
        backgroundColor: "#DEDEDE",
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '20px',
        overflow: 'auto',
        maxWidth: '530px'
    },

    ameya_icon: {
        width: '24px',
        height: '23px',
        // borderRadius: '50%',
        objectFit: 'cover',
        // margin: '4px',
        backgroundColor: '#DEDEDE',
        backgroundImage: `url(${ameya_logo})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },


    // Svg Icons:

    svgIcons: {
        height: '30px',
        width: '30px',
        color: '#FFFFFF',
        stroke: "#FFFFFF"
        // fill: "#FFFFFF"
    }

}