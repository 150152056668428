// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  list-style-type: none;
  padding: 0;
  margin-top: 30px;

}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s ease;
}

.menu-item .icon,
.menu-item .label {
  color: #ffffff;
  transition: color 0.3s ease;
}

.menu-item:hover {
  margin: 10px;
  border-radius: 10px;
  background-color: #0B51C5;
}

.menu-item:hover .icon,
.menu-item:hover .label {
  color: #ffffff;
}

.menu-item.active {
  background-color: #007bff; 
  border-radius: 10px;
}

.menu-item.active .icon,
.menu-item.active .label {
  color: #ffffff;
}

.menu-item.active:hover {
  background-color: #007bff; 
}

.menu-item.active:hover .icon,
.menu-item.active:hover .label {
  color: #ffffff;
}

.subsidebar_icon svg {
  color: white;
  transition: color 0.3s ease;
  stroke: #FFFFFF;
  transition: stroke 0.3s ease;
}

.subsidebar_icon:hover svg {
  color: #FFFFFF;
  stroke: #FFFFFF;
}

.subsidebar_icon.active svg {
  color: #ffffff;
  stroke: #ffffff;
}

.subsidebar_icon.active:hover svg {
  color: #ffffff;
  stroke: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/containers/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,UAAU;EACV,gBAAgB;;AAElB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,YAAY;EACZ,sCAAsC;AACxC;;AAEA;;EAEE,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,2BAA2B;EAC3B,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".menu {\n  list-style-type: none;\n  padding: 0;\n  margin-top: 30px;\n\n}\n\n.menu-item {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 10px;\n  cursor: pointer;\n  margin: 10px;\n  transition: background-color 0.3s ease;\n}\n\n.menu-item .icon,\n.menu-item .label {\n  color: #ffffff;\n  transition: color 0.3s ease;\n}\n\n.menu-item:hover {\n  margin: 10px;\n  border-radius: 10px;\n  background-color: #0B51C5;\n}\n\n.menu-item:hover .icon,\n.menu-item:hover .label {\n  color: #ffffff;\n}\n\n.menu-item.active {\n  background-color: #007bff; \n  border-radius: 10px;\n}\n\n.menu-item.active .icon,\n.menu-item.active .label {\n  color: #ffffff;\n}\n\n.menu-item.active:hover {\n  background-color: #007bff; \n}\n\n.menu-item.active:hover .icon,\n.menu-item.active:hover .label {\n  color: #ffffff;\n}\n\n.subsidebar_icon svg {\n  color: white;\n  transition: color 0.3s ease;\n  stroke: #FFFFFF;\n  transition: stroke 0.3s ease;\n}\n\n.subsidebar_icon:hover svg {\n  color: #FFFFFF;\n  stroke: #FFFFFF;\n}\n\n.subsidebar_icon.active svg {\n  color: #ffffff;\n  stroke: #ffffff;\n}\n\n.subsidebar_icon.active:hover svg {\n  color: #ffffff;\n  stroke: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
