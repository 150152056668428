import globalstyes from "../../../styles/globalstyes.json";

export const Styles = {
    mainContainer: {
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        padding: '20px'
    },

}