import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selected_project: []
};

const selectedDataSlice = createSlice({
    name: 'selected_data',
    initialState,
    reducers: {
        setSelectedProject(state, action) {
            state.selected_project = action.payload;
        }
    },
});

export const { setSelectedProject } = selectedDataSlice.actions;

export default selectedDataSlice.reducer;
