import DocumentsApi from "../../../Api/Services/AmeyaBackendAPI/DocumentsApi";

const JsonSourceFiles = async () => {

    const getAllJsonSourceFiles = async () => {
        const tempArr = []
        try {
            const response = await DocumentsApi.getJsonDataSource();
            if (response.data) {
                const response_data = response?.data ?? []
                tempArr.push(...response_data)
            }
            return tempArr;
        }
        catch (error) {
            console.error(error)
        }
    }

    return getAllJsonSourceFiles();
};

export default JsonSourceFiles;