import globalstyes from './globalstyes.json';
export const tableStyles = {
    tcontainer: {
        borderRadius: '0',
        boxShadow: 'none',
        fontFamily: 'Inter',
    },

    thead: {
        width: '100%'
    },

    trow: {
        width: '100%',
        cursor: 'pointer',
        '&:hover': { backgroundColor: globalstyes.colors.trowHover },
    },

    sortlabel: {
        '& .MuiTableSortLabel': {
            color: 'white',
        },
        '&:hover .MuiTableSortLabel-active .MuiTableSortLabel': {
            color: 'white',
        },
        '& .MuiTableSortLabel-icon': {
            color: 'white',
        },
        '&:hover .MuiTableSortLabel-icon, &.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
            color: 'white',
        },
    },

    thcell: {
        color: globalstyes.colors.textPrimary,
        backgroundColor: '#222C31',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '500',
        paddingTop: '5px',
        paddingBottom: '5px',
        '&:hover, &.MuiTableSortLabel-active': {
            '& .MuiTableSortLabel-icon': { color: 'white' }
        },
    },

    theadText: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '30px',
        fontFamily: `${globalstyes.fonts.font_family}, ${globalstyes.fallbackfont.font_family}`,
        color: '#FFFFFF'
    }
    ,
    tdcell: {
        color: globalstyes.colors.textSecondary,
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '400',
        paddingTop: '13px',
        paddingBottom: '13px'
    },

    emptytr: {
        width: '100%'
    },

    emptytdcell: {
        color: globalstyes.colors.textSecondary,
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '400',
        textAlign: 'center'
    },

}