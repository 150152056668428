export const all_spaces = [
    {
        'name': 'Test Workspace1',
        'desc': 'Test Workspace1',
        'projects': [
            { 'name': 'Test Project1(ws1)', 'desc': 'Test Project1' },
            { 'name': 'Test Project2(ws1)', 'desc': 'Test Project2' },
            { 'name': 'Test Project3(ws1)', 'desc': 'Test Project3' },
            { 'name': 'Test Project4(ws1)', 'desc': 'Test Project4' }
        ]
    },
    {
        'name': 'Test Workspace2',
        'desc': 'Test Workspace2',
        'projects': [
            { 'name': 'Test Project1(ws2)', 'desc': 'Test Project1' },
            { 'name': 'Test Project2(ws2)', 'desc': 'Test Project2' },
            { 'name': 'Test Project3(ws2)', 'desc': 'Test Project3' },
            { 'name': 'Test Project4(ws2)', 'desc': 'Test Project4' }
        ]
    },
    {
        'name': 'Test Workspace3',
        'desc': 'Test Workspace3',
        'projects': [
            { 'name': 'Test Project1(ws3)', 'desc': 'Test Project1' },
            { 'name': 'Test Project2(ws3)', 'desc': 'Test Project2' },
            { 'name': 'Test Project3(ws3)', 'desc': 'Test Project3' },
            { 'name': 'Test Project4(ws3)', 'desc': 'Test Project4' }
        ]
    },
    {
        'name': 'Test Workspace4',
        'desc': 'Test Workspace4',
        'projects': [
            { 'name': 'Test Project1(ws4)', 'desc': 'Test Project1' },
            { 'name': 'Test Project2(ws4)', 'desc': 'Test Project2' },
            { 'name': 'Test Project3(ws4)', 'desc': 'Test Project3' },
            { 'name': 'Test Project4(ws4)', 'desc': 'Test Project4' }
        ]
    },
    {
        'name': 'Test Workspace5',
        'desc': 'Test Workspace5',
        'projects': [
            { 'name': 'Test Project1(ws5)', 'desc': 'Test Project1' },
            { 'name': 'Test Project2(ws5)', 'desc': 'Test Project2' },
            { 'name': 'Test Project3(ws5)', 'desc': 'Test Project3' },
            { 'name': 'Test Project4(ws5)', 'desc': 'Test Project4' }
        ]
    }
]


