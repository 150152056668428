export const Styles = {
    mainContainer: {
        width: '100%',
        height: '100vh',
    },

    unProcessDocumentContainer: {
        width: '350px',
        height: '90px',
        marginTop: '30px',
        paddingBottom: '30px',
        backgroundColor: '#EEEEEE',
        borderRadius: '10px',
        border: '1px solid #EEEEEE',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

}
