export const Styles = {
    mainContainer: {
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: '#F5F7FB'
    },
    body: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90%'
    },
    signinContainer: {
        height: '300px',
        width: '390px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
    },
    signinButton: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
        border: '1px solid #747775',
        borderRadius: '5px',
        padding: '5px 10px 5px 10px',
        cursor: 'pointer'
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10%',
        padding: '0px 10px 10px 10px'
    }
}