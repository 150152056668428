import { createSlice } from "@reduxjs/toolkit";

const initialState = null;
const currentProjectSlice = createSlice({
    name: 'current_project',
    initialState,
    reducers: {
        setCurrentProject(state, action) {
            return action.payload;
        },
    }
});

export const { setCurrentProject } = currentProjectSlice.actions;
export default currentProjectSlice.reducer;