import { useDispatch } from "react-redux";
import FilesApi from "../../../Api/Services/DataSource/galleryService";

const ProjectSourceFiles = async (folderId) => {


    const getAllProjectSourceFiles = async (folderId) => {
        const tempArr = []
        try {
            const response = await FilesApi.getSourceFoldersById(folderId);
            if (response.data) {
                const foldersFiles = response?.data?.files;
                tempArr.push(...foldersFiles)
            }
            return tempArr;
        }
        catch (error) {
            console.error(error)
        }
    }

    return getAllProjectSourceFiles(folderId);
};

export default ProjectSourceFiles;