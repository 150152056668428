import { Box, Button, FormControl, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Styles } from './Styles';
import { fontStyles } from '../../../styles/fontStyles'
import { searchBarStyles } from '../../../styles/searchBarStyles';
import { buttonStyles } from '../../../styles/buttonStyles';
import { Icon } from '@iconify/react';

function DataSourceDatabse() {

    const [databaseData, setDatabaseData] = useState({
        databasetype: 'MongoDB',
        database_name: '',
        database_server_url: '',
        database_user: '',
        database_password: '',
    });

    const handleDatabaseDataChange = (e) => {
        const { name, value } = e.target;
        setDatabaseData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDatabaseConnect = async (e) => {
        e.preventDefault();
        console.log(databaseData);
    }

    return (
        <Box sx={Styles.mainContainer}>

            <Typography sx={fontStyles.largeText}>Sources - Database</Typography>
            <Typography sx={fontStyles.smallText}>Connect to databases, query and visualize your data with natural language</Typography>

            <Box marginTop={'20px'}>

                <Box display={'flex'} alignItems={'center'}>

                    <Box
                        sx={{ ...Styles.datasource, backgroundColor: databaseData.databasetype === 'MongoDB' ? '#D9D9D9' : '#FFFFFF' }}
                        onClick={() => setDatabaseData({ ...databaseData, databasetype: 'MongoDB' })}
                    >
                        <Icon icon="teenyicons:mongodb-outline" width={'20px'} height={'20px'} />
                        <Typography sx={fontStyles.smallText}>Mongo DB</Typography>
                    </Box>


                    <Box
                        sx={{ ...Styles.datasource, backgroundColor: databaseData.databasetype === 'MySQL' ? '#D9D9D9' : '#FFFFFF' }}
                        onClick={() => setDatabaseData({ ...databaseData, databasetype: 'MySQL' })}
                    >
                        <Icon icon="tabler:brand-mysql" width={'20px'} height={'20px'} />
                        <Typography sx={fontStyles.smallText}>My SQL</Typography>
                    </Box>


                    <Box
                        sx={{ ...Styles.datasource, backgroundColor: databaseData.databasetype === 'Snowflake' ? '#D9D9D9' : '#FFFFFF' }}
                        onClick={() => setDatabaseData({ ...databaseData, databasetype: 'Snowflake' })}
                    >
                        <Icon icon="simple-icons:snowflake" width={'20px'} height={'20px'} />
                        <Typography sx={fontStyles.smallText}>Snowflake</Typography>
                    </Box>

                </Box>
            </Box>

            <Box marginTop={'40px'}>

                <Box display={'flex'} alignItems={'center'} component="form"
                    onSubmit={handleDatabaseConnect}
                >
                    <FormControl>
                        <Typography sx={{ marginBottom: '4px' }}>Connection Name</Typography>
                        <TextField
                            id="app-name"
                            variant="outlined"
                            placeholder='URL'
                            size='small'
                            sx={{ ...searchBarStyles.searchBar, width: '250px' }}
                            name="database_name"
                            value={databaseData.database_name}
                            onChange={handleDatabaseDataChange}
                        />
                    </FormControl>
                    <FormControl sx={{ marginLeft: '10px' }}>
                        <Typography sx={{ marginBottom: '4px' }}>Database Server URL</Typography>
                        <TextField
                            id="app-name"
                            variant="outlined"
                            placeholder='URL'
                            size='small'
                            sx={{ ...searchBarStyles.searchBar, width: '250px' }}
                            name="database_server_url"
                            value={databaseData.database_server_url}
                            onChange={handleDatabaseDataChange}
                        />
                    </FormControl>
                    <FormControl sx={{ marginLeft: '10px' }}>
                        <Typography sx={{ marginBottom: '4px' }}>User</Typography>
                        <TextField
                            id="app-name"
                            variant="outlined"
                            placeholder='URL'
                            size='small'
                            sx={{ ...searchBarStyles.searchBar, width: '250px' }}
                            name="database_user"
                            value={databaseData.database_user}
                            onChange={handleDatabaseDataChange}
                        />
                    </FormControl>
                    <FormControl sx={{ marginLeft: '10px' }}>
                        <Typography sx={{ marginBottom: '4px' }}>Password</Typography>
                        <TextField
                            id="app-name"
                            variant="outlined"
                            placeholder='URL'
                            size='small'
                            sx={{ ...searchBarStyles.searchBar, width: '250px' }}
                            name="database_password"
                            value={databaseData.database_password}
                            onChange={handleDatabaseDataChange}
                        />
                    </FormControl>

                    <Button
                        sx={{ ...buttonStyles.primaryBtn, width: '150px', marginLeft: '15px', marginTop: '20px' }}
                        type="submit"
                    >
                        <Typography sx={fontStyles.btnText}>CONNECT</Typography>
                    </Button>

                </Box>

            </Box>

        </Box >
    )
}

export default DataSourceDatabse