import { Box } from '@mui/material';
import React from 'react'
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { Styles } from './Styles';

function DocSearchApi() {

  return (
    <Box sx={Styles.mainContainer}>
      <SwaggerUI
        url={`${process.env.REACT_APP_DOCUMENT_SEARCH_APIS_URL}`}
        deepLinking={true}
        docExpansion="none"
        defaultModelsExpandDepth={-1}
        defaultModelExpandDepth={1}
        layout="BaseLayout"
        showExtensions={true}
        showCommonExtensions={true}
      />
    </Box>
  )
}

export default DocSearchApi;