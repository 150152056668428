import globalstyes from "../../styles/globalstyes.json";

export const Styles = {
    mainContainer: {
        width: "100%",
        backgroundColor: '#ffffff'
    },


    card: {
        height: '100%',
        cursor: 'pointer',
        borderRadius: '20px',
        padding: '10px',
        backgroundColor: '#F3F5F7'
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#F3F5F7',
        padding: '10px 20px 10px 20px',
        color: '#000000',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    cardBody: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        padding: '0px 20px 10px 20px',
        backgroundColor: '#F3F5F7',
        color: '#000000',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
}