export const Styles = {
    sidebar: {
        height: '100vh',
        width: '180px',
        background: '#F5F7FB',
        position: 'fixed',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
        color: '#000000',
    },
    // menuItemIcon: {
    //     width: '18px',
    //     height: '12px',
    //     display: 'flex',
    //     alignItems: 'center',
    //     top: '6px',
    //     gap: '0px',
    //     border: '1.5px 0px 0px 0px',
    //     opacity: ' 0px',
    //     color: '#FFFFFF'
    // },
    // menuItemLabel: {
    //     width: '35px',
    //     height: '30px',
    //     top: '192px',
    //     left: '26px',
    //     gap: '0px',
    //     opacity: '0px',
    //     color: '#FFFFFF'
    // },

    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px',
        cursor: 'pointer',
        margin: '10px',
        transition: 'background-color 0.3s ease',
    },
    menuItemIcon: {
        color: '#000000',
        marginRight: '8px',
    },
    menuItemLabel: {
        color: '#000000',
        transition: 'color 0.3s ease',
    },
    menuItemHover: {
        margin: '10px',
        borderRadius: '10px',
        backgroundColor: '#DEDEDE',
    },
    menuItemActive: {
        margin: '10px',
        borderRadius: '10px',
        backgroundColor: '#DEDEDE',
    },
    menuItemHoverIcon: {
        color: '#000000',
    },
    menuItemHoverLabel: {
        color: '#000000',
    },
    menuItemActiveIcon: {
        color: 'red',
        fontWeight: 'bold',
    },
    menuItemActiveLabel: {
        color: 'red',
        fontWeight: 'bold',
    },
}