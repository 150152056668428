import { Box, Button, IconButton, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Styles } from './Styles';
import { fontStyles } from '../../../styles/fontStyles'
import { searchBarStyles } from '../../../styles/searchBarStyles';
import { Add, AddCircleOutline, DeleteOutline } from '@mui/icons-material';

function DataSourceLinks() {
    return (
        <Box sx={Styles.mainContainer}>

            <Typography sx={fontStyles.largeText}>Sources - Links</Typography>
            <Typography sx={fontStyles.smallText}>Add web links to train Ameya</Typography>

            <Box marginTop={'20px'}>
                <Typography sx={fontStyles.smallText}>Website URL</Typography>

                <Box marginTop={'20px'}>
                    <Box display={'flex'} alignItems={'center'}>
                        <TextField
                            id="app-name"
                            variant="outlined"
                            placeholder='URL'
                            size='small'
                            sx={{ ...searchBarStyles.searchBar, width: '40%' }}
                        />
                        <IconButton sx={{ marginLeft: '10px' }}>
                            <AddCircleOutline color='success' />
                        </IconButton>
                    </Box>

                    <Box marginTop={'20px'} display={'flex'} alignItems={'center'}>
                        <TextField
                            id="app-description"
                            variant="outlined"
                            placeholder='App Description'
                            size='small'
                            sx={{ ...searchBarStyles.searchBar, width: '40%' }}
                        />
                        <IconButton sx={{ marginLeft: '10px' }}>
                            <DeleteOutline color='error' />
                        </IconButton>
                    </Box>

                </Box>

            </Box>

        </Box>
    )
}

export default DataSourceLinks