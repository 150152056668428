export const breadCrumbsStyles = {

    '@font-face': {
        fontFamily: 'Inter',
        src: 'url("./Fonts/Inter-Regular.ttf")',
    },

    linkHeader: {
        fontSize: '15px',
        fontWeight: '500',
        color: '#000000',
        fontFamily: 'Inter',
    },

    nonlinkHeader: {
        fontSize: '18px',
        fontWeight: '600',
        color: '#000000',
        fontFamily: 'Inter',
        textDecoration: 'none',
    },
}