import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from "react-svg";
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';
import { Menu, Apps, Home, Folder, Settings, Launch, Workspaces, DashboardCustomizeOutlined, HomeOutlined } from '@mui/icons-material';


import { Styles } from "./Styles"
import './Sidebar.css';
import { routesConfig } from '../../Routes/Routes';
import { IconSvg } from '../../utils/globalIcons';

function Sidebar() {

  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate()

  const current_space = useSelector(state => state.current_space)
  const current_project = useSelector(state => state.current_project)
  const selected_project = useSelector(state => state.selected_data.selected_project)

  const [currentspace, setCurrentSpace] = useState(null)
  const [selectedMenuItem, setSelectedMenuItem] = useState(1);

  useEffect(() => {
    setCurrentSpace(current_space)
    if (!current_space) navigate('/spaces')
  }, [current_space])

  const matchRoute = (route) => {
    let regexPath = route.path
      .replace(/:\w+/g, '[^/]+')
      .replace(/\/\*$/, '(?:/.*)?');

    const regex = new RegExp(`^${regexPath}$`);
    return regex.test(currentPath);
  };

  const menuItems = [
    { icon: IconSvg.spaceIcon, label: 'Spaces' },
    { icon: IconSvg.projectIcon, label: 'Projects' },
    { icon: IconSvg.insightIcon, label: 'Insights' },
    { icon: IconSvg.sourcesIcon, label: 'Sources' },
    { icon: IconSvg.settingsIcon, label: 'Settings' },
    { icon: IconSvg.launchIcon, label: 'Launch' }
  ];

  const filteredMenuItems = () => {

    let items = menuItems.filter(item => item.label === '' || item.label === 'Spaces');

    if (current_space && !current_project) {
      items.push(menuItems.find(item => item.label === 'Projects'));
    }

    if (current_space && current_project && selected_project) {
      const main_sidebar_items = ['Spaces', 'Projects'];
      const selected_sidebar_items = selected_project?.project_engine_config?.engine_config?.sidebar_items ?? []

      const allowedLabels = new Set(
        Object.keys(selected_sidebar_items).map(label => label.charAt(0).toUpperCase() + label.slice(1))
      );

      const filteredMenuItems = menuItems.filter(item =>
        allowedLabels.has(item.label) || main_sidebar_items.includes(item.label)
      );
      items = filteredMenuItems;
    }

    return items;
  };

  useEffect(() => {
    const currentRoute = routesConfig.find(matchRoute)
    const label = currentRoute ? currentRoute?.component : '';
    const componentIndex = filteredMenuItems()?.findIndex(item => item?.label === label);
    setSelectedMenuItem(componentIndex);
  }, [location])

  const handlePageRouting = (label) => {
    switch (label) {
      case 'Spaces':
        navigate('/spaces')
        break;

      case 'Projects':
        currentspace ? navigate(`/spaces/${currentspace}/projects`) : navigate('/projects')
        break;

      case 'Sources':
        navigate('/datasource')
        break;

      case 'Insights':
        navigate(`/spaces/${currentspace}/projects/${current_project}/insights`)
        break;

      case 'Settings':
        navigate('/settings')
        break;

      case 'Launch':
        navigate('/launch')
        break;

      default:
        navigate('/spaces')
    }
  };

  const handleMenuItemClick = (index, label) => {
    setSelectedMenuItem(index);
    handlePageRouting(label)
  };

  return (

    <nav style={Styles.sidebar}>
      <ul className="menu">
        {filteredMenuItems()?.map((item, index) => (
          <li key={index}
            className={`menu-item ${selectedMenuItem === index ? 'active' : ''}`}
            onClick={() => handleMenuItemClick(index, item.label)}
          >
            <span className="icon">
              <ReactSVG
                src={item.icon}
                className={`subsidebar_icon ${selectedMenuItem === index ? 'active' : ''}`}
              />
            </span>
            <Typography className="label" variant="caption">{item.label}</Typography>
          </li>
        ))}

      </ul>
    </nav>
  );
}

export default Sidebar;