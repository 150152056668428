import axios from "axios";

const AxiosObj = axios.create(
    {
        baseURL: process.env.REACT_APP_APPFLYTE_API_BASE_URL,
        responseType: "json"
    }
)

const requestHandler = async (request) => {
    // const JwtToken = JSON.parse(localStorage.getItem("dpod-token"))
    const JwtToken = 'eyJraWQiOiIyQWdqdklWQzV1N1owMDNFYlFHT0RHb2s0dGhGV2xDRE9HbktvN2tOZU5zPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJkYTliMThjNS00NTY2LTRjODAtOWE3OS1mMDZjMTkyMWM5MmUiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX1FIM2NaZUNoMiIsImNpcmNsZXMiOiJbXSIsImNvZ25pdG86dXNlcm5hbWUiOiJkYTliMThjNS00NTY2LTRjODAtOWE3OS1mMDZjMTkyMWM5MmUiLCJhcHBfbmFtZSI6IkRQT0QgQ29hY2ggQXBwIiwiYXVkIjoiNmVzMHZlcXNuMTJkaDg5NGNzdHBoazllOGEiLCJldmVudF9pZCI6IjAxMjFlYjQ3LTM0YWItNGZiOS1iMjA2LTYxZWVlNzU2ZmZjZCIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNzMxNDgxMDQzLCJuYW1lIjoiQW1leWEiLCJwaG9uZV9udW1iZXIiOiIrOTE3Nzc3Nzg4ODg4IiwiYWNjb3VudHMiOiJ7XCJhbWV5YS1kZXZcIjoge1wiYWNjb3VudElkXCI6IFwiYTE0MmU5M2QtN2NlNS00ZmI1LWJiZDEtNjE2Mzc4YmZlYmIwXCIsIFwidXNlcklkXCI6IFwiZGE5YjE4YzUtNDU2Ni00YzgwLTlhNzktZjA2YzE5MjFjOTJlXCIsIFwic3Vic2NyaXB0aW9uSWRcIjogXCIwYzg1YmRhMi1mNGEwLTRlNzktYjBiOS00YTg4MWFlMjQ1MGFcIiwgXCJzdWJzY3JpYmVySWRcIjogXCJkNWRhODliMy1hODFmLTQ0OTgtYjk1Yi1kZGJlZGYxMzA1ZTNcIiwgXCJpc0FkbWluXCI6IHRydWUsIFwiYWNjb3VudFJvbGVcIjogXCJEb2N0b3JcIiwgXCJhY2NvdW50VHlwZVwiOiBcImNvbXBhbnlcIiwgXCJpc19hY3RpdmVcIjogdHJ1ZSwgXCJzdGF0dXNcIjogXCJBY3RpdmVcIiwgXCJ0cmlhbF9wZXJpb2RfZW5kX2RhdGVcIjogXCIyMDI1LTAyLTExXCIsIFwiZGF0ZV91bnN1YnNjcmliZWRcIjogbnVsbCwgXCJ2YWxpZF90b1wiOiBudWxsLCBcInN1YnNjcmlwdGlvbl9lbmRfZGF0ZVwiOiBudWxsfX0iLCJleHAiOjE3MzE2NjI2MzIsImlhdCI6MTczMTY1OTAzNCwiaW5hY3RpdmVfYWNjb3VudHMiOiJ7fSIsImVtYWlsIjoiZGVla3NoaXRoQGRwb2QuaW8ifQ.YYiruWmoRnAuDVIfdKICH-PAaOt8Z-2CnV98rAX_BboqseMlTVQ4dpCqxbPta9GvUb_FFMgFR8W51zNHc31fe7GxtyNQehuJmPxvSMu0SL6skEOgPDa_w_aaLnX-asnZJkSGxr7uAPjFPs-YEWlPvKEC8UUmuxww0PReD2Spbfa2kNlQ5f1pHxUzbNobP0thVpfTXpzNJp-FnUhimi0TykB9KZ9BabFXfwdkjf2TfXhyXExus-c-EmOzr7HVZknKgi9e_aGopaoLy5cLXzd8Hlto3Lp7xptTwyCT8lqpaDdY_LRGiFHZ5nbm4gDIkVwscuFzNyBHYDdEfWyY3ZKwpA'
    request.headers['Authorization'] = `Bearer ${JwtToken}`;
    request.headers['Content-Type'] = 'application/json';
    return request
}

AxiosObj.interceptors.request.use(
    request => requestHandler(request)
)

export default AxiosObj;

