import axios from "axios";

const AxiosObjForFile = axios.create(
    {
        baseURL: process.env.REACT_APP_APPFLYTE_FILE_API_BASE_URL ?? 'https://api.livgood.in/v1/platform',
        responseType: "json"
    }
)

const requestHandler = async (request) => {
    // const JwtToken = JSON.parse(localStorage.getItem("dpod-token"))
    const JwtToken = 'eyJraWQiOiIyQWdqdklWQzV1N1owMDNFYlFHT0RHb2s0dGhGV2xDRE9HbktvN2tOZU5zPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJkYTliMThjNS00NTY2LTRjODAtOWE3OS1mMDZjMTkyMWM5MmUiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX1FIM2NaZUNoMiIsImNpcmNsZXMiOiJbXSIsImNvZ25pdG86dXNlcm5hbWUiOiJkYTliMThjNS00NTY2LTRjODAtOWE3OS1mMDZjMTkyMWM5MmUiLCJhcHBfbmFtZSI6IkRQT0QgQ29hY2ggQXBwIiwiYXVkIjoiNmVzMHZlcXNuMTJkaDg5NGNzdHBoazllOGEiLCJldmVudF9pZCI6IjM1NTg0ZTUwLWM3MDQtNGY2NC1hYmI4LTg4NjAzN2IwYjQyNyIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNzMzMTM1MTMyLCJuYW1lIjoiQW1leWEiLCJwaG9uZV9udW1iZXIiOiIrOTE3Nzc3Nzg4ODg4IiwiYWNjb3VudHMiOiJ7XCJhbWV5YS1kZXZcIjoge1wiYWNjb3VudElkXCI6IFwiYTE0MmU5M2QtN2NlNS00ZmI1LWJiZDEtNjE2Mzc4YmZlYmIwXCIsIFwidXNlcklkXCI6IFwiZGE5YjE4YzUtNDU2Ni00YzgwLTlhNzktZjA2YzE5MjFjOTJlXCIsIFwic3Vic2NyaXB0aW9uSWRcIjogXCIwYzg1YmRhMi1mNGEwLTRlNzktYjBiOS00YTg4MWFlMjQ1MGFcIiwgXCJzdWJzY3JpYmVySWRcIjogXCJkNWRhODliMy1hODFmLTQ0OTgtYjk1Yi1kZGJlZGYxMzA1ZTNcIiwgXCJpc0FkbWluXCI6IHRydWUsIFwiYWNjb3VudFJvbGVcIjogXCJEb2N0b3JcIiwgXCJhY2NvdW50VHlwZVwiOiBcImNvbXBhbnlcIiwgXCJpc19hY3RpdmVcIjogdHJ1ZSwgXCJzdGF0dXNcIjogXCJBY3RpdmVcIiwgXCJ0cmlhbF9wZXJpb2RfZW5kX2RhdGVcIjogXCIyMDI1LTAyLTExXCIsIFwiZGF0ZV91bnN1YnNjcmliZWRcIjogbnVsbCwgXCJ2YWxpZF90b1wiOiBudWxsLCBcInN1YnNjcmlwdGlvbl9lbmRfZGF0ZVwiOiBudWxsfX0iLCJleHAiOjE3MzMxMzg3MzEsImlhdCI6MTczMzEzNTEzMiwiaW5hY3RpdmVfYWNjb3VudHMiOiJ7fSIsImVtYWlsIjoiZGVla3NoaXRoQGRwb2QuaW8ifQ.Up_P82mi8zOjOcFj3-qAVKLH4mkd2tH2ZENcfhk10nJYrLJePxAg_jK9o1t0glIbSnZRiYuYt9H_F1l7Z68IzjCYDgOoe8muZtZij7XSC8twVvzD6z6t8jhEKd9bRnBL4xhPAAX0FgoLXGuqp3l0KiCgX51i4hoH9U6cUhGnqD4kWlPVKTO_SscoTfbpoTSfncGVaizgDOVNWWZl8W26HOa19sFfAeRKpU13D6msM_KFnLskIMLXJ8iUcQad-Jp6ven3jT0vVpu-KScakAZ5E7aH0CgUx52LkJgKUJtTox6WSsPnGjczWeRIyeW03mLsefDxDkhp6w4UBgR0_dRFYg'
    request.headers['Authorization'] = `Bearer ${JwtToken}`;
    request.headers['Content-Type'] = 'application/json';
    return request
}

AxiosObjForFile.interceptors.request.use(
    request => requestHandler(request)
)

export default AxiosObjForFile;

