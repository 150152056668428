import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box, Button, Card, CardContent, Grid2 as Grid,
    IconButton, Typography, Link, Stack, Breadcrumbs
} from '@mui/material';
import { MoreVert, NavigateNextRounded } from '@mui/icons-material';

import { Styles } from './Styles';
import { buttonStyles } from '../../styles/buttonStyles';
import { fontStyles } from '../../styles/fontStyles';
import { mainStyles } from '../../styles/styles';
import { breadCrumbsStyles } from '../../styles/breadCrumbs';

import { setCurrentProject } from "../../Redux/slice/currentProjectSlice";
import { setSelectedProject } from "../../Redux/slice/selectedDataSlice";

import getProjectData from '../../utils/Data/ProjectData';
import { setProjectsState, setSpacesState } from "../../Redux/slice/dataSlice";
import LoadBar from '../../utils/LoadBar';
import getSpaceData from '../../utils/Data/SpaceData';
import { handleSidebarConfig } from '../../utils/handleSidebarConfig';
import { setProjectAdded, setWorkspaceAddedAdded } from '../../Redux/slice/newDataSlice';

function ViewProjects() {

    const navigate = useNavigate()
    const { space_id } = useParams()
    const dispatch = useDispatch()
    const [currentspace, setCurrentSpace] = useState(null)
    const current_project = useSelector(state => state.current_project)
    const all_projects = useSelector(state => state.all_data.projects)
    const all_spaces = useSelector(state => state.all_data.spaces)
    const project_added = useSelector(state => state.data_added.project_added)
    const workspace_added = useSelector(state => state.data_added.workspace_added)

    const [projects, setProjects] = useState([])
    const [projectLoading, setProjectLoading] = useState(false);

    useEffect(() => {
        getAllData(space_id)
    }, [space_id])

    const getSpaceDetails = async () => {
        let response
        if (all_spaces?.length > 0 && workspace_added === false) {
            response = all_spaces
        } else {
            response = await getSpaceData()
            dispatch(setSpacesState(response))
            dispatch(setWorkspaceAddedAdded(false))
        }
        const filterd_space_id = all_spaces?.filter(space => space?.payload?.workspace_name?.en === space_id).map(space => space?.payload?.__auto_id__)?.[0] ?? null;
        return filterd_space_id
    }

    const getProjectDetails = async () => {
        if (all_projects?.length > 0 && project_added === false) {
            return all_projects
        }
        const response = await getProjectData()
        dispatch(setProjectsState(response))
        dispatch(setProjectAdded(false))
        return response
    }

    const getAllData = async (space_id) => {
        setProjectLoading(true)
        try {
            const [spaceResponse, projectsResponse] = await Promise.all([
                getSpaceDetails(space_id),
                getProjectDetails(),
            ]);
            if (spaceResponse && projectsResponse) {
                const filterd_projects = projectsResponse?.filter(project => project?.payload?.ameya_workspacess?.includes(spaceResponse))
                setCurrentSpace(spaceResponse)
                setProjects(filterd_projects)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setProjectLoading(false)
        }
    }


    const handleProjectCreation = async () => {
        navigate(`/spaces/${currentspace}/projects/add`, { state: { space_name: space_id } })
    }

    const handleProjectSelection = async (item, item_name, item_update_key) => {
        const updatedItem = { ...item, update_key: item_update_key };
        dispatch(setCurrentProject(item_name))
        dispatch(setSelectedProject(updatedItem))
        await handleSidebarConfig(updatedItem, space_id, current_project, navigate)
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit"
            onClick={() => navigate('/spaces')}
            sx={breadCrumbsStyles.linkHeader}>
            <Typography>All Spaces</Typography>
        </Link>,
        <Typography key="1" sx={breadCrumbsStyles.nonlinkHeader}>
            {space_id}
        </Typography>,
    ];

    return (
        <div style={Styles.mainContainer}>
            {projectLoading && (<LoadBar />)}
            <Box padding={'20px'}>

                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>

                    <Stack spacing={2} sx={{ marginLeft: '0' }}>
                        <Breadcrumbs
                            separator={<NavigateNextRounded fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Stack>

                    <Button
                        sx={{ ...buttonStyles.primaryBtn, width: '130px' }}
                        onClick={() => handleProjectCreation()}
                    >
                        <Typography sx={fontStyles.btnText}>New Project</Typography>
                    </Button>
                </Box>

                <Box marginTop={'50px'}>
                    <Grid container spacing={6}>
                        {projects && projects.length > 0 ? projects?.map((project, index) => (
                            <Grid size={{ xs: 6, md: 3 }} key={index} >
                                <Card sx={Styles.card}>
                                    <CardContent sx={{ padding: '0' }} onClick={() => handleProjectSelection(project?.payload, project?.payload?.project_name?.en, project?.update_key)}>
                                        <Box sx={Styles.cardHeader}>
                                            <Typography sx={fontStyles.mediumText}>
                                                {project?.payload?.project_name?.en}
                                            </Typography>
                                            <IconButton>
                                                <MoreVert />
                                            </IconButton>
                                        </Box>
                                        <Box sx={Styles.cardBody}>
                                            <Typography sx={fontStyles.smallText}>
                                                {project?.desc}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )) :
                            (projects.length === 0 && !projectLoading) && (
                                <Box sx={mainStyles.noRecord}>
                                    <Typography>No Records To Display</Typography>
                                </Box>
                            )}
                    </Grid>
                </Box>


            </Box>

        </div>
    )
}

export default ViewProjects;