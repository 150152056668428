import AxiosObj from "../Configurations/axios-setup";
import { fetchAccountId } from "../../utils/GetAccountDetails"

class dpodappFlyteApi {

    constructor() {
        this.accountId = null;
    }

    async initialize() {
        this.accountId = await fetchAccountId();
    }

    createWorkspace = async (reqObj) => {
        await this.initialize();
        return AxiosObj.post(`/${this.accountId}/api/collection/${this.accountId}/user/private/cm/v1/ameya_workspacess`, reqObj)
            .then((response) => {
                return response;
            })
    }

    getAllWorkspaces = async (last_evaluated_key) => {
        await this.initialize();
        return AxiosObj.get(`/${this.accountId}/api/collection/${this.accountId}/user/private/cm/v1/ameya_workspacess?filters=null&last_evaluated_key=${last_evaluated_key}&page_size=50&include_detail=true`)
            .then((response) => {
                return response;
            })
    }

}

const WorkspaceApi = new dpodappFlyteApi();
export default WorkspaceApi;


