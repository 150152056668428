import globalstyes from './globalstyes.json';

export const searchBarStyles = {

    '@font-face': {
        fontFamily: 'Inter',
        src: 'url("./Fonts/Inter-Regular.ttf")',
    },

    searchBar: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
            color: 'black',
            width: '100%',
            // '&.Mui-focused': {
            //     backgroundColor: '#FFFFFF',
            //     color: 'black',
            //     boxShadow: ` 0 0 0 1px ${globalstyes.colors.btnPrimary}`
            // },
        },

    },

    dropdown: {
        backgroundColor: '#FFFFFF',
        color: 'black',
        width: '100%',
        '&.Mui-focused': {
            backgroundColor: '#FFFFFF',
            color: 'black',
            border: `0.5px solid ${globalstyes.colors.btnPrimary}`,
        },
    }

}
