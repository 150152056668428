import DocumentsApi from "../../../Api/Services/AmeyaBackendAPI/DocumentsApi";

const PdfSourceFiles = async () => {

    const getAllPdfSourceFiles = async () => {
        const tempArr = []
        try {
            const response = await DocumentsApi.getPdfDataSource();
            if (response.data) {
                const response_data = response?.data ? response?.data : []
                tempArr.push(...response_data)
            }
            return tempArr;
        }
        catch (error) {
            console.error(error)
        }
    }

    return getAllPdfSourceFiles();
};

export default PdfSourceFiles;